import React, { useState } from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import './MyCKEditor.css';


const editorConfig = {
    removePlugins: ['ImageUpload', 'EasyImage', 'MediaEmbed' ]
  };

const MyCKEditor = props => {
    return (
        <React.Fragment>
            <div className="editor">
                <CKEditor
                    editor={ClassicEditor}
                    config={editorConfig}
                    data=""
                    onChange={(event, editor) => {
                        const data = editor.getData();
                        props.onEditorChange(data);
                    }}
                />
            </div>
        </React.Fragment>
    );

};

export default MyCKEditor;
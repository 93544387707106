import { useEffect, useState, useRef } from 'react';
import { Col, Row, Alert, Button } from 'react-bootstrap';

import EventDetailHeader from './EventDetailHeader';

import { connect } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import getEventDetail from 'api/events/get-event';
import {
  map,
  upperCase
} from 'lodash';
import { BsTv } from 'react-icons/bs';
import Flex from 'components/common/Flex';
import { MdArrowBackIos } from 'react-icons/md';

import { BracketMatches } from './UpcomingMatches';
import { useTopBrackets } from './useTopBrackets';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleDown, faChevronCircleUp } from '@fortawesome/free-solid-svg-icons'


const mapStateToProp = state => ({});

export const MatBracketCard = ({ bracket, isHighlighted,
  index,
  bracketId,
  isPublic,
  isTVMode,
  eventInfo,
  matId
}) => {

  const [isMatchesOpen, setIsMatchesOpen] = useState(true);

  const { canWeDisplayBracketUpcomingMatches } = useTopBrackets({
    eventInfo,
    matId,
    bracketId,
  })

  return (
    <>
      <div
        className={`border mb-1 bg-white p-1 rounded-1 matBracket-status 
        ${bracket.startTime != null ? 'matBracket-in-progress-status' : ''}
        ${bracket.endTime != null ? 'matBracket-completed-status' : ''}
        ${isHighlighted ? "bracketsMatScheduleTopTwoBracketDiv" : ""}
        ${bracket.startTime != null && isHighlighted && bracket.endTime == null ? 'matBracket-in-progress-highlighted-status' : ''}
        ${bracket.endTime != null && isHighlighted ? 'matBracket-completed-highlighted-status' : ''}`
        }
      >
        <Flex>
          <div className="flex-1 position-relative ps-3">
            <h6>
              <div className="pt-1 d-flex justify-content-between">
                <span>{bracket.description}</span>
                <small className="">{'-'}</small>
              </div>
            </h6>
          </div>
        </Flex>

        {/* Don't display the Toggle button in TV Mode */}
        {canWeDisplayBracketUpcomingMatches && !isTVMode &&
          (
            <div className='d-flex justify-content-between'>
              <div />
              <FontAwesomeIcon
                className='upcoming-matches-toggle-btn'
                icon={!isMatchesOpen ? faChevronCircleUp : faChevronCircleDown} onClick={() => {
                  setIsMatchesOpen((prev) => !prev);
                }} />
            </div>
          )
        }

      </div>

      {isMatchesOpen &&
        (
          <BracketMatches academyId={bracket.academyId} bracketId={bracketId}
            isPublic={isPublic} isTVMode={isTVMode} eventInfo={eventInfo} matId={matId} />
        )
      }



    </>
  );
};

const MatDetail = ({ eventInfo, matId, isTVMode, isPublic }) => {
  const { t } = useTranslation();

  const { matBrackets = [], topTwoBrackets = [], bracketsWithoutTopTwo = [] } = useTopBrackets({ eventInfo, matId });


  const bracketListRef = useRef();

  useEffect(() => {
    if (bracketListRef.current) {
      bracketListRef.current.scroll({
        top: 0,
        behavior: 'smooth'
      });
    }
  }, [matBrackets]);



  return (
    <>
      <Row className="container">
        <h4 className={isTVMode ? 'text-white' : ''}>
          {upperCase(t('events.eventDetail.mats.title'))} {matId}
        </h4>
      </Row>
      <Row
        className="container bracketsMatScheduleMatList"
        ref={bracketListRef}
      >
        <div className="bracketsMatScheduleTopTwoMatList" >
          {map(topTwoBrackets, (bracket, index) => {
            return (
              <MatBracketCard
                key={index} isHighlighted={true}
                bracket={bracket}
                bracketId={bracket.bracketUUID} isPublic={isPublic} isTVMode={isTVMode} eventInfo={eventInfo} matId={matId}
              />);
          })}
        </div>

        {map(bracketsWithoutTopTwo, (bracket, index) => {
          return (
            <MatBracketCard key={index} bracket={bracket}
              bracketId={bracket.bracketUUID} isPublic={isPublic} isTVMode={isTVMode} eventInfo={eventInfo} matId={matId}
            />);
        })}
      </Row>
    </>
  );
};

export const MatSchedules = ({
  eventInfo,
  matId,
  academyId,
  eventId,
  isTVMode,
  isPublic
}) => {
  const { t } = useTranslation();

  const bracketMats = eventInfo?.bracketMats || [];

  return (
    <>
      {eventInfo && (
        <>
          <Row className="g-3 my-3">
            <Col lg={12}>
              <Row className="mx-1">
                <div className="d-flex justify-content-between">
                  <div></div>

                  {isTVMode ? (
                    <Button
                      size="sm"
                      variant="falcon-default"
                      className="me-2"
                      as={Link}
                      to={
                        isPublic
                          ? `/public-events/mat/${academyId}/${eventId}/${matId}`
                          : `/dashboard/events/mat/${academyId}/${eventId}/${matId}`
                      }
                    >
                      <MdArrowBackIos /> {t('events.eventDetail.mats.back')}
                    </Button>
                  ) : (
                    <Button
                      size="sm"
                      variant="falcon-default"
                      className="me-2"
                      as={Link}
                      to={`/public-events/mat/tv/${academyId}/${eventId}/${matId}/${location.pathname.includes('dashboard') ? 0 : 1
                        }`}
                    >
                      <BsTv /> {t('events.eventDetail.mats.tvMode')}
                    </Button>
                  )}
                </div>
              </Row>
            </Col>

            <div className={`bracketsMatScheduleRow ${isTVMode ? "bracketsMatScheduleRow-TV" : ""}`}>
              {map(bracketMats, mat => {
                return (
                  <div className={`bracketsMatScheduleCol ${isTVMode ? "bracketsMatScheduleCol-TV" : ""}`} key={mat}>
                    <MatDetail
                      matId={mat}
                      eventInfo={eventInfo}
                      isTVMode={isTVMode}
                      isPublic={isPublic}
                    />
                  </div>
                );
              })}
            </div>
          </Row>
        </>
      )}
    </>
  );
};

const MatScheduleDetail = () => {
  const { t } = useTranslation();
  const { id, academyId, matId } = useParams();
  const [eventInfo, setEventInfo] = useState(null);
  const [loading, isLoading] = useState(true);

  const loadData = () => {
    (async () => {
      const data = await getEventDetail(academyId, id);
      setEventInfo(data);
      isLoading(false);
    })();
  };

  useEffect(() => {
    loadData();
    const REFRESH_INTERVAL_RATE = 1 * 60 * 1000;
    const intervalId = setInterval(loadData, REFRESH_INTERVAL_RATE);
    return () => {
      clearInterval(intervalId);
    };
  }, [id]);

  if (loading) {
    return (
      <Alert variant="default" className="mb-0 rounded-0">
        <h4 className="alert-heading">{t('events.eventDetail.loading')}</h4>
      </Alert>
    );
  }

  return (
    <>
      <EventDetailHeader eventInfo={eventInfo} titleAsLink={true} />
      <MatSchedules
        eventInfo={eventInfo}
        academyId={academyId}
        eventId={id}
        matId={matId}
      />
    </>
  );
};

export default connect(mapStateToProp)(MatScheduleDetail);

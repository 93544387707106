import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import defaultProfile from 'assets/img/team/avatar.png';
import friendlyUrl from 'friendly-url';


const Follower = ({ follower, academy }) => {
  const { avatarUrl, name, uuid} = follower;
  const[avatar, setAvatar] = useState(avatarUrl)
  
  const handleImgError = () => {
   setAvatar(defaultProfile);
  };
  
  return (
    <div className="bg-white p-3 h-100 justify-content-center">
      <Link to={`/athlete/${friendlyUrl(name)}/${uuid}`} target="_blank" className="d-flex justify-content-center">
      <div style={{ width: '80px', height: '80px' }}>
        <Image
          thumbnail
          fluid
          roundedCircle
          className="mb-3 shadow-sm"
          src={avatar}
          onError={handleImgError}
          width={80}
          height={80}
          style={{ objectFit: 'cover', width: '100%', height: '100%' }}
        />
      </div>
      </Link>
      <h6 className="mb-1">
        <label>{name}</label>
      </h6>
      <p className="fs--5 mb-1">
        <Link className="text-700" to="#!">
          {academy}
        </Link>
      </p>
    </div>
  );
};

Follower.propTypes = {
  follower: PropTypes.shape({
    avatarUrl: PropTypes.string,
    name: PropTypes.string,
    academy: PropTypes.string
  })
};

export default Follower;

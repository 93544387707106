/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import Section from 'components/common/Section';
import { connect } from 'react-redux';
import getProfile from 'api/user/get-profile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LastMatches from 'components/dashboards/fighter/profile/LastMatches';
import moment from 'moment';
import team3 from 'assets/img/team/3.jpg';
import Avatar from 'components/common/Avatar';
import AvatarUploader from 'components/common/AvatarUploader';
import { useTranslation } from 'react-i18next';
import ACTION_TYPE from '../../../../dux/auth/ACTION_TYPE';
import { getDevAPIRoot } from 'api/utils';
import UserRegistrations from './UserRegistrations';
import friendlyUrl from 'friendly-url';
import { replace } from "lodash";

const mapStateToProp = state => ({
  academyId: state.auth.academyId,
  uuid: state.auth.uuid,
  userId: state.auth.userId
});

export const Profile = ({ academyId, userId, uuid, dispatch }) => {
  const [athleteData, setAthleteData] = useState(null);
  const { t } = useTranslation();

  const emitAvatarChange = useCallback(async () => {
    const data = await getProfile(uuid);
    if (data) {
      setAthleteData(data[0]);
      dispatch({
        type: ACTION_TYPE.SET_AVATAR,
        avatar: data[0].avatarUrl
      });
    }
  }, []);

  const loadData = async () => {
    const data = await getProfile(uuid);
    if (data) {
      setAthleteData(data[0]);
    }
  };

  useEffect(() => {
    loadData();
  }, []);
  return (
    <>
      {athleteData && (
        <>
          <Section bg="light" className="py-3 profile mb-4">
            <div className="profile-editor">
              <div className="img-holder">
                <Avatar src={athleteData.avatarUrl || team3} />
                <AvatarUploader
                  url={`${getDevAPIRoot()}/development/slickcomp-images/user/${uuid}.png`}
                  onDone={emitAvatarChange}
                />
              </div>
            </div>
            <div className="profile-details">
              <header className="profile-header">
                <div className="text">
                  {athleteData.name && (
                    <Link
                      to={`/athlete/${friendlyUrl(athleteData.name)}/${
                        athleteData.uuid
                      }`}
                    >
                      <h5>{athleteData.name}</h5>
                    </Link>
                  )}
                  <ul className="sub-items">
                    {athleteData.createTime && (
                      <li>
                        <span className="badge rounded-pill badge-soft-primary">
                          {t('profile.memberSince')}:{' '}
                          {moment(athleteData.createTime).format('DD/MM/YYYY')}
                        </span>
                      </li>
                    )}
                    {athleteData.age && (
                      <li>
                        <span className="badge rounded-pill badge-soft-primary">
                          {t('profile.age')}: {athleteData.age}
                        </span>
                      </li>
                    )}
                    {athleteData.belt && (
                      <li>
                        <span className="badge rounded-pill badge-soft-primary">
                          {t('profile.belt')}: {athleteData.belt}
                        </span>
                      </li>
                    )}
                    {athleteData.weight && (
                      <li>
                        <span className="badge rounded-pill badge-soft-primary">
                          {t('profile.weight')}: {athleteData.weight}
                        </span>
                      </li>
                    )}
                    {athleteData.academyName && (
                      <li>
                        <span className="badge rounded-pill badge-soft-primary">
                          {t('profile.academy')}: {athleteData.academyName}
                        </span>
                      </li>
                    )}
                  </ul>
                </div>
                <div>
                  <Link
                    className="edit-profile"
                    to={`/dashboard/user/edit/${uuid}`}
                  >
                    {t('profile.editProfile')}
                  </Link>
                </div>
                <ul className="social">
                  <li>
                    <Link
                      to={`/athlete/${friendlyUrl(athleteData?.name)}/${
                        athleteData?.uuid
                      }`}                    >
                      <FontAwesomeIcon icon={'globe'} color="#5D6E82" />
                    </Link>
                  </li>
                  {athleteData.facebook && (
                    <li>
                      <a
                        href={athleteData.facebook}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          icon={['fab', 'facebook-f']}
                          color="#5D6E82"
                        />
                      </a>
                    </li>
                  )}
                  {athleteData.instagram && (
                    <li>
                      <a
                        href={`https://instagram.com/${replace(athleteData.instagram, new RegExp("https://www.instagram.com/", "g"), "") || "#"}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          icon={['fab', 'instagram']}
                          color="#5D6E82"
                        />
                      </a>
                    </li>
                  )}
                  {athleteData.linkedin && (
                    <li>
                      <a
                        href={athleteData.linkedin}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          icon={['fab', 'linkedin-in']}
                          color="#5D6E82"
                        />
                      </a>
                    </li>
                  )}
                </ul>
              </header>
              {athleteData.intro && (
                <div className="profile-detail">
                  <p>{athleteData.intro}</p>
                </div>
              )}
            </div>
          </Section>
          <LastMatches academyId={academyId} userId={userId} />
          <UserRegistrations uuid={uuid} />
        </>
      )}
    </>
  );
};

export default connect(mapStateToProp)(Profile);

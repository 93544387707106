import { useCallback, useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';

import Flex from 'components/common/Flex';
import { useTranslation } from 'react-i18next';
import { map, orderBy, take, find, compact, isEmpty, filter } from 'lodash';

import { useTopBrackets } from './useTopBrackets';


const getMatchInfo = (match) => {
    return {
        matchId: match.matchId,
        matchDate: match.match_datetime,
        matchEndTime: match.match_endtime,
        estimatedStartTime: match.estimated_start_time,
        matchResults: JSON.parse(match.result),
        matchWinner: match.match_winner,
        matchLoser: match.match_loser,
        teams: compact([
            match.participant1_name != null ? {
                id: match.user1_id,
                name: match.participant1_name,
                country: match.participant1_country,
                academyName: match.participant1_academy,
            } : null,
            match.participant2_name != null ? {
                id: match.user2_id,
                name: match.participant2_name,
                country: match.participant2_country,
                academyName: match.participant2_academy,
            } : null,
        ])
    }
}

const MatchCard = ({ match, isTVMode }) => {

    const isMatchCompleted = match.matchResults != null;
    const isMatchOngoing = match.matchDate != null && match.matchEndTime == null && match.matchResults == null;

    if (isEmpty(match.teams)) {
        return null;
    }

    return (
        <div>
            <div
                className={`border mb-2 bg-white p-1 rounded-1 upcoming-match-bracket 
            ${isMatchOngoing ? "upcoming-match-in_progress-status" : ''}
            ${isMatchCompleted ? "upcoming-match-completed-status" : ''}
          `}
            >
                <Flex>
                    <div className="flex-1 position-relative ps-1">
                        <div className='d-flex justify-content-between'>
                            <div>
                                {match.teams.map((player) => {
                                    return (
                                        <div className={`pt-1 d-flex upcoming-results-match-div
                                        ${isTVMode ? "upcoming-results-match-div-tv" : ""}
                                        `}>
                                            <div>
                                                <div class={`results-badge 
                                                ${isTVMode && "results-badge-tv-mode"}
                                                        ${match.matchWinner === player.id ? "win-results-badge" : ""}
                                                        ${match.matchLoser === player.id ? "lose-results-badge" : ""}
                                                        ${match.matchLoser == null || match.matchWinner == null ? "none-results-badge" : ""}
                                                `}>
                                                    <div class="results-badge__label">
                                                        {match.matchWinner === player.id ? "WIN" : ""}
                                                        {match.matchLoser === player.id ? "LOSE" : ""}
                                                    </div>
                                                </div>
                                            </div>
                                            <h5>
                                                {player.name}
                                                <br />
                                                <small>
                                                    {player.academyName}
                                                </small>
                                            </h5>
                                        </div>
                                    )
                                })}
                            </div>

                            <div className='align-center d-flex'>
                                <h6 className="m-auto">{match.estimatedStartTime || '-'}</h6>
                            </div>


                        </div>
                    </div>
                </Flex>
            </div>
        </div>

    )
}



export const BracketMatches = ({
    eventInfo,
    matId,
    bracketId,
    isTVMode,
    isPublic
}) => {

    const { t } = useTranslation();

    const { canWeDisplayBracketUpcomingMatches } = useTopBrackets({
        eventInfo,
        matId,
        bracketId,
    });

    const getRounds = useCallback((bracketData) => {
        let rounds = [];

        map(bracketData.matches, (match) => {
            rounds.push({
                ...getMatchInfo(match),
            })
        });

        return rounds;

    }, []);

    const [bracketDisplay, setBracketDisplay] = useState({});
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        const bracketInfo = find(eventInfo.brackets, (bracket) => bracket.bracketUUID === bracketId);
        bracketInfo["matchRounds"] = orderBy(getRounds(bracketInfo), m => m.matchId, ['asc']);

        setBracketDisplay(bracketInfo);
        setLoading(false);
    }, [])


    if (!bracketDisplay) {
        return null;
    }

    if (!canWeDisplayBracketUpcomingMatches) {
        return null;
    }



    return (
        <div className='bracket-upcoming-matches'>
            {loading && (
                <Alert variant="default" className="mb-0 rounded-0">
                    <h4 className="alert-heading">{t('events.eventDetail.loading')}</h4>
                </Alert>
            )}

            <h6 className={`mb-3 upcoming-bracket-title ${isTVMode ? 'text-white' : ''}`}>
                <small>
                    <b>
                        {t("events.eventDetail.mats.upcomingMatches")}
                    </b>
                </small>
            </h6>

            {!loading && isEmpty(filter(bracketDisplay.matchRounds, (m => m.matchId != null))) && (
                <small>
                    {t("events.eventDetail.mats.noUpcomingMatches")}
                </small>
            )}

            {/* Take only the next Upcoming Four Matches */}
            <div>
                {map(take(bracketDisplay.matchRounds, 4), (match) => {
                    return (
                        <MatchCard match={match} key={match.matchId} isTVMode={isTVMode} />
                    )
                })}
            </div>

            <hr />

        </div>
    )

}

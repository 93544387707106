import React, { Suspense } from 'react';
import { getAPIRoot } from 'api/utils';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import Layout from './layouts/Layout';
import i18n from './i18n';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { ToastContainer, ToastTransition } from 'react-toastify';
import { injectStyle } from "react-toastify/dist/inject-style";

if (typeof window !== "undefined") {
  injectStyle();
}

const App = () => {
  i18n.on('languageChanged', () => i18n.language);
  return (
    <PayPalScriptProvider
      deferLoading={true}
      options={{
        'client-id': process.env.REACT_APP_PAYPAL_CLIENT_ID,
        components: 'buttons,hosted-fields',
        "data-namespace": "paypal_sdk",
        vault: true,
      }}
    >
      <Suspense fallback={null}>
        <Router basename={process.env.PUBLIC_URL}>
          <Layout />
          <ToastContainer
            position={'bottom-left'}
            theme={'colored'}
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Router>
      </Suspense>

    </PayPalScriptProvider>
  );
};

export default App;

/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { useTranslation } from 'react-i18next';
import { useGetMatchByIdQuery, useGetMatchResultQuery } from 'api/match';
import { useParams } from 'react-router-dom';
import { useGetUsersQuery } from 'api/user';
import moment from 'moment';
import MatchResultDefault from './match-result-default/index';
import MatchResultIBJJF from './mach-result-ibjjf/index';

import MatchVideo from './Match-video';

const MatchResult = ({ match }) => {
  const { t } = useTranslation();
  const { matchid: matchId, academyId } = useParams();
  const {
    data: matchData,
    error,
    isLoading
  } = useGetMatchByIdQuery({ matchId, academyId });
  const { data } = useGetMatchResultQuery({ matchId, academyId });
  const { data: participants } = useGetUsersQuery(academyId);
  const getParticipantData = id => {
    if (matchData[0]?.participant1?.id === id) {
      return matchData[0].participant1;
    }
    if (matchData[0]?.participant2?.id === id) {
      return matchData[0].participant2;
    }
    if (matchData[0]?.participant1?.id != id) {
      return matchData[0].participant1;
    }
    if (matchData[0]?.participant2?.id != id) {
      return matchData[0].participant2;
    }
  };

  const createVideoArray = videString => videString.split(',');
  let duration = 0;
  let videos = null;
  if (data && data[0]) {
    data[0].match_videos &&
      data[0].match_videos !== null &&
      (videos = createVideoArray(data[0].match_videos));
    if (JSON.parse(data[0].result).matchDuration) {
      duration = JSON.parse(data[0].result).matchDuration;
    } else if (matchData && matchData[0]) {
      duration = matchData[0].duration;
    }
  }

  return (
    <>
      <div className="match-result">
        <div className="match-header mb-3">
          <h5 className="mb-1">{t('matchResults.pageTitle')}</h5>
          {matchData && (
            <ul className="sub-items">
              {duration && duration.toString() && (
                <li>
                  <span className="badge rounded-pill badge-soft-primary">
                    {t('matchResults.duration')}:{' '}
                    {moment.utc(duration * 1000).format('mm:ss')}
                  </span>
                </li>
              )}
              {matchData[0]?.matchType && (
                <li>
                  <span className="badge rounded-pill badge-soft-primary">
                    {t('matchResults.matchType')}: {matchData[0].matchType}
                  </span>
                </li>
              )}
              {matchData[0]?.matchGender && (
                <li>
                  <span className="badge rounded-pill badge-soft-primary">
                    {t('matchResults.matchGender')}: {matchData[0].matchGender}
                  </span>
                </li>
              )}
              {matchData[0]?.giNogi !== undefined && (
                <li>
                  <span className="badge rounded-pill badge-soft-primary">
                    {t('matchResults.giNogi')}:{' '}
                    {matchData[0].giNogi === 1 ? 'Gi' : 'NoGi'}
                  </span>
                </li>
              )}
              {matchData[0]?.seniority && (
                <li>
                  <span className="badge rounded-pill badge-soft-primary">
                    {t('matchResults.seniority')}: {matchData[0].seniority}
                  </span>
                </li>
              )}
              {matchData[0]?.weight && (
                <li>
                  <span className="badge rounded-pill badge-soft-primary">
                    {t('matchResults.weight')}: {matchData[0].weight}
                  </span>
                </li>
              )}
              {matchData[0]?.matchEndtime && (
                <li>
                  <span className="badge rounded-pill badge-soft-primary">
                    {t('matchResults.endTime')}:{' '}
                    {moment(matchData[0].matchEndtime).format('MM-DD-YYYY')}
                  </span>
                </li>
              )}
            </ul>
          )}
        </div>
        {data &&
          participants &&
          (matchData[0]?.scoreboardType === 1 ||
          matchData[0]?.scoreboardType === null ? (
            <MatchResultDefault
              data={data}
              getParticipantData={getParticipantData}
            />
          ) : matchData[0]?.scoreboardType === 2 ? (
            <MatchResultIBJJF
              data={data}
              getParticipantData={getParticipantData}
            />
          ) : (
            ''
          ))}
      </div>
      {videos && <MatchVideo className="gallery" videos={videos} />}
    </>
  );
};

export default MatchResult;

import DatePicker, { registerLocale } from 'react-datepicker';
import { Form } from 'react-bootstrap';
import CustomDateInput from 'components/common/CustomDateInput';
import PropTypes from 'prop-types';

import en from 'date-fns/locale/en-US';
import es from 'date-fns/locale/es';
import pt from 'date-fns/locale/pt';
import { useTranslation } from 'react-i18next';

const datePickerLocales = {
  en: en,
  es: es,
  pt: pt
};

const DefaultDateTimePicker = ({
  value,
  dateFormat,
  placeholder,
  onValueChange,
  isInvalid,
  errorMessage,
  showTimeSelect = true,
  showTimeSelectOnly = false,
  timeIntervals = 30,
  timeCaption = null,
  showClearIcon = false,
  minDate = new Date(),
  maxDate = null,
  showMonthDropdown = false,
  showYearDropdown = false,
  dropdownMode = 'select'
}) => {
  const { i18n } = useTranslation();

  return (
    <div className="d-flex flex-column w-100">
      <DatePicker
        className="form-control w-100"
        selected={value}
        showTimeSelectOnly={showTimeSelectOnly}
        timeIntervals={timeIntervals}
        timeCaption={timeCaption}
        showTimeSelect={showTimeSelect}
        showMonthDropdown={showMonthDropdown}
        showYearDropdown={showYearDropdown}
        dropdownMode={dropdownMode}
        dateFormat={dateFormat}
        locale={datePickerLocales[i18n.language]}
        minDate={minDate}
        maxDate={maxDate}
        onChange={newDate => onValueChange(newDate)}
        isInvalid={isInvalid}
        customInput={
          <CustomDateInput
            errorMessage={errorMessage ?? ''}
            showClearIcon={showClearIcon}
            onClear={() => onValueChange(null)}
            isInvalid={isInvalid}
            formControlProps={{
              placeholder: placeholder,
              className: !isInvalid
                ? 'w-100'
                : 'is-invalid event-date--invalid-input w-100'
            }}
          />
        }
      />
    </div>
  );
};

DefaultDateTimePicker.propTypes = {
  value: PropTypes.any,
  dateFormat: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onValueChange: PropTypes.func.isRequired,
  isInvalid: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string
};
DefaultDateTimePicker.defaultProps = {
  dateFormat: 'dd/MM/yyyy h:mm aa',
  placeholder: 'd/m/y H:i AM/PM',
  value: new Date()
};

export default DefaultDateTimePicker;

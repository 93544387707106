import { Card } from 'react-bootstrap';
import { Col, Form, Row, Button } from 'react-bootstrap';
import { upperCase } from 'lodash';
import { useTranslation } from 'react-i18next';
import IconButton from 'components/common/IconButton';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import * as yup from 'yup';
import CountrySelect from 'react-bootstrap-country-select';

import { retrieveBillingInfo, putBillingInfo, postBillingInfo } from 'api/events/billing-info';
import { toast } from 'react-toastify';


const BillingInformationForm = ({ billingInfoForEdit, onSuccess }) => {
    const { t } = useTranslation();
    const { userId } = useSelector(state => state.auth);

    const [formData, setFormData] = useState({
        email: '',
        country: '',
        firstName: '',
        lastName: '',
        companyName: '',
        nationalID: '',
        street: '',
        houseNumber: '',
        houseAdditional: '',
        postalCode: '',
        city: '',
        phone: ''
    });
    const [errors, setErrors] = useState([]);
    const [processing, setProcessing] = useState(false);

    const formSchema = yup.object().shape({
        country: yup.object().required(),
        firstName: yup.string().required(),
        lastName: yup.string().required(),
        street: yup.string().required(),
        houseNumber: yup.string().required(),
        postalCode: yup.string().required(),
        city: yup.string().required(),
        phone: yup.string().required(),
        email: yup.string().email().required()
    });

    useEffect(() => {
        if (billingInfoForEdit) {
            setFormData({
                ...billingInfoForEdit,
                country: {
                    id: billingInfoForEdit.country,
                }
            });
        }
    }, [billingInfoForEdit]);

    const handleFormChange = (inputKey, inputValue) => {
        const updatedFormData = { ...formData, [inputKey]: inputValue };
        setFormData(updatedFormData);
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();


        (formSchema)
            .validate(formData, { abortEarly: false })
            .then(async () => {
                setErrors({});
                setProcessing(true);

                try {

                    const update = formData.id ? putBillingInfo : postBillingInfo;
                    const { id, dateInserted, dateUpdated, ...formDataWithoutId } = formData;
                    const data = formData.id ? { ...formDataWithoutId, billingID: formData.id, country: formData?.country?.id, } : {
                        ...formData,
                        country: formData?.country?.id,
                        userID: userId,
                    };

                    const result = await update(data);

                    if (result.statusCode === 200) {
                        toast.success(t('events.eventDetail.entryDetail.billing.saveSuccessToast'));
                        await onSuccess();
                    } else {
                        toast.error(result.body);
                    }
                } finally {
                    setProcessing(false);
                }

            })
            .catch(err => {
                console.log('err', err);
                if (err.name === 'ValidationError') {
                    let errs = {};
                    err.inner.flatMap(e => {
                        errs[e.path] = e.errors;
                    });
                    setErrors(errs);
                }
            });
    }


    return (
        <>
            <div className='mx-2'>
                <h5 className="fs-0 mb-3">
                    <span>{billingInfoForEdit ?
                        t('events.eventDetail.entryDetail.billing.editButton') : t('events.eventDetail.entryDetail.billing.addButton')}</span>
                </h5>
                <div>
                    <Row className="gx-2 gy-3">

                        <Form.Group as={Col} lg={6} controlId="email">
                            <Form.Label>{t('events.eventDetail.entryDetail.billing.emailLabel')} *</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder={t('events.eventDetail.entryDetail.billing.emailPlaceholder')}
                                value={formData.email}
                                name="email"
                                className={errors?.email ? "border border-1 border-danger" : ""}
                                onChange={({ nativeEvent }) => {
                                    handleFormChange("email", nativeEvent.target.value);
                                }}
                            />
                        </Form.Group>

                        <Form.Group as={Col} lg={6} controlId="country">
                            <Form.Label>{t('events.eventDetail.entryDetail.billing.countryLabel')} *</Form.Label>
                            <CountrySelect
                                placeholder={t('events.eventDetail.entryDetail.billing.countryPlaceholder')}
                                flags={true}
                                flush={true}
                                value={formData.country}
                                onChange={value => {
                                    handleFormChange("country", value);
                                }}
                            />
                        </Form.Group>

                        <Form.Group as={Col} lg={6} controlId="firstName">
                            <Form.Label>{t('events.eventDetail.entryDetail.billing.firstNameLabel')} *</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={t('events.eventDetail.entryDetail.billing.firstNamePlaceholder')}
                                className={errors?.firstName ? "border border-1 border-danger" : ""}
                                value={formData.firstName}
                                name="firstName"
                                onChange={({ nativeEvent }) => {
                                    handleFormChange("firstName", nativeEvent.target.value);
                                }}
                            />
                        </Form.Group>

                        <Form.Group as={Col} lg={6} controlId="lastName">
                            <Form.Label>{t('events.eventDetail.entryDetail.billing.lastNameLabel')} *</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={t('events.eventDetail.entryDetail.billing.lastNamePlaceholder')}
                                value={formData.lastName}
                                className={errors?.lastName ? "border border-1 border-danger" : ""}

                                name="lastName"
                                onChange={({ nativeEvent }) => {
                                    handleFormChange("lastName", nativeEvent.target.value);
                                }}
                            />
                        </Form.Group>

                        <Form.Group as={Col} lg={6} controlId="vat">
                            <Form.Label>{t('events.eventDetail.entryDetail.billing.vatNrLabel')}</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={t('events.eventDetail.entryDetail.billing.vatNrPlaceholder')}
                                value={formData.nationalID}
                                name="nationalID"
                                onChange={({ nativeEvent }) => {
                                    handleFormChange("nationalID", nativeEvent.target.value);
                                }}
                            />
                        </Form.Group>

                        <Form.Group as={Col} lg={12} controlId="street">
                            <Form.Label>{t('events.eventDetail.entryDetail.billing.streetNameLabel')} *</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={t('events.eventDetail.entryDetail.billing.streetNamePlaceholder')}
                                value={formData.street}
                                className={errors?.street ? "border border-1 border-danger" : ""}

                                name="street"
                                onChange={({ nativeEvent }) => {
                                    handleFormChange("street", nativeEvent.target.value);
                                }}
                            />
                        </Form.Group>

                        <Form.Group as={Col} lg={2} controlId="houseNumber">
                            <Form.Label>{t('events.eventDetail.entryDetail.billing.houseNumberLabel')} *</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={t('events.eventDetail.entryDetail.billing.houseNumberPlaceholder')}
                                value={formData.houseNumber}
                                name="houseNumber"
                                className={errors?.houseNumber ? "border border-1 border-danger" : ""}

                                onChange={({ nativeEvent }) => {
                                    handleFormChange("houseNumber", nativeEvent.target.value);
                                }}
                            />
                        </Form.Group>

                        <Form.Group as={Col} lg={4} controlId="houseAdditional">
                            <Form.Label>{t('events.eventDetail.entryDetail.billing.additionLabel')}</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={t('events.eventDetail.entryDetail.billing.additionPlaceholder')}
                                value={formData.houseAdditional}
                                name="houseAdditional"
                                onChange={({ nativeEvent }) => {
                                    handleFormChange("houseAdditional", nativeEvent.target.value);
                                }}
                            />
                        </Form.Group>

                        <Form.Group as={Col} lg={4} controlId="postalCode">
                            <Form.Label>{t('events.eventDetail.entryDetail.billing.postalCodeLabel')} *</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={t('events.eventDetail.entryDetail.billing.postalCodePlaceholder')}
                                value={formData.postalCode}
                                name="postalCode"
                                className={errors?.postalCode ? "border border-1 border-danger" : ""}

                                onChange={({ nativeEvent }) => {
                                    handleFormChange("postalCode", nativeEvent.target.value);
                                }}
                            />
                        </Form.Group>

                        <Form.Group as={Col} lg={12} controlId="City">
                            <Form.Label>{t('events.eventDetail.entryDetail.billing.cityLabel')} *</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={t('events.eventDetail.entryDetail.billing.cityPlaceholder')}
                                value={formData.city}
                                name="city"
                                className={errors?.city ? "border border-1 border-danger" : ""}

                                onChange={({ nativeEvent }) => {
                                    handleFormChange("city", nativeEvent.target.value);
                                }}
                            />
                        </Form.Group>

                        <Form.Group as={Col} lg={6} controlId="phone">
                            <Form.Label>{t('events.eventDetail.entryDetail.billing.phoneNumberLabel')} *</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={t('events.eventDetail.entryDetail.billing.phoneNumberPlaceholder')}
                                value={formData.phone}
                                name="phone"
                                className={errors?.phone ? "border border-1 border-danger" : ""}
                                onChange={({ nativeEvent }) => {
                                    handleFormChange("phone", nativeEvent.target.value);
                                }}
                            />
                        </Form.Group>

                        <Col lg={6} className="mt-5">
                            <div className='d-flex'>
                                <i className='my-auto'>{t('events.eventDetail.entryDetail.billing.phoneNumberInfo')}</i>
                            </div>
                        </Col>

                        <Col
                            md={12}
                            className="ps-xxl-5 text-center text-md-start text-xl-center text-xxl-start d-flex justify-content-center mt-2 mb-3"
                        >
                            <div className="border-dashed-bottom d-block d-md-none d-xl-block d-xxl-none my-4"></div>

                            <Button
                                disabled={processing}
                                variant="primary"
                                type="button"
                                onClick={handleFormSubmit}
                                className="mt-3 px-5 mx-auto"
                            >
                                {processing ? t("events.eventDetail.entryDetail.billing.saveProcessBtn") : t("events.eventDetail.entryDetail.billing.saveBtn")}
                            </Button>
                        </Col>

                    </Row>
                </div>
            </div>

        </>
    )
}

export const BillingInformationSection = ({
    entryInfo,
    selectedBillingDataId,
    setSelectedBillingDataId,
    billingInfoList: billingInfo,
    setBillingInfoList: setBillingInfo,
}) => {

    const { entry } = entryInfo;

    const { t } = useTranslation();
    const { uuid } = useSelector(state => state.auth);

    const [loading, setLoading] = useState(true);
    const [isInInputMode, setIsInputMode] = useState(false);
    const [billingInfoForEdit, setBillingInfoForEdit] = useState(null);

    const handleLoadBillingInfo = async () => {
        try {
            const info = await retrieveBillingInfo({
                athleteUuid: uuid
            });
            setBillingInfo(info);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        (async () => {
            await handleLoadBillingInfo();
        })();
    }, [uuid]);

    //don't show billing info Card, when price is zero
    if (Number(entry.price) === 0) {
        return null;
    }

    return (
        <Card className="mb-3">
            <Card.Header className="bg-light">
                <Row className="flex-between-center">
                    <Col sm="auto">
                        <h5 className="mb-0">
                            <span>{t('events.eventDetail.entryDetail.billing.title')}</span>
                        </h5>
                    </Col>
                    <Col sm="auto">
                        <IconButton
                            iconClassName="me-2"
                            variant="falcon-default"
                            size="sm"
                            icon={isInInputMode ? 'arrow-left' : 'plus'}
                            transform="shrink-2"
                            onClick={() => {
                                setIsInputMode((prev) => !prev);
                                setBillingInfoForEdit(null);
                            }}
                        >
                            <span>{isInInputMode ? t('events.eventDetail.entryDetail.billing.backBtn') : t('events.eventDetail.entryDetail.billing.addButton')}</span>
                        </IconButton>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body>

                {loading && <div>{t("events.listEvents.loading")}</div>}
                {isInInputMode &&
                    (<BillingInformationForm
                        billingInfoForEdit={billingInfoForEdit}
                        onSuccess={async () => {
                            await handleLoadBillingInfo();
                            setIsInputMode(false);
                        }} />
                    )}
                {!isInInputMode &&
                    <Row>
                        {billingInfo.map((item, index) => (
                            <Col key={item.id} md={6} className="mb-3">
                                <Form.Check
                                    type="radio"
                                    id={`${item.id}`}
                                    checked={selectedBillingDataId == item.id}
                                    onChange={e => setSelectedBillingDataId(e.target.id)}
                                    className="mb-0 form-check radio-select"
                                >
                                    <Form.Check.Input
                                        type="radio"
                                        id={`${item.id}`}
                                        onChange={e => setSelectedBillingDataId(e.target.id)}
                                        checked={selectedBillingDataId == item.id}
                                        name="clientAddress"
                                    />
                                    <Form.Check.Label className="mb-0 fw-bold d-block">
                                        <span>
                                            {`${item.firstName} ${item.lastName}`}
                                        </span>
                                        <span className="radio-select-content">
                                            <span>
                                                {item.houseNumber} {item.street}, <br /> {item.city}, <br /> {upperCase(item.country)},{' '}
                                                {item.postalCode}{' '}
                                                <span className="d-block mb-0 pt-2">{item.phone}</span>
                                            </span>
                                        </span>
                                    </Form.Check.Label>
                                    <Button
                                        variant="link"
                                        onClick={() => {
                                            setIsInputMode(true);
                                            setBillingInfoForEdit(item);
                                        }}
                                        className="fs--1">
                                        <span>{t('events.eventDetail.entryDetail.billing.editButton')}</span>
                                    </Button>
                                </Form.Check>
                            </Col>
                        ))}
                    </Row>
                }

            </Card.Body>
        </Card>
    );
};

const BillingInformation = ({ entryInfo, handleFormChange, formData, errors }) => {

    const { entry } = entryInfo;
    const { t } = useTranslation();

    //don't show billing info form, when price is zero
    if (Number(entry.price) === 0) {
        return null;
    }

    return (
        <div className='mx-2'>
            <h5 className="fs-2 me-1 mb-2 mt-0">
                <span>{t('events.eventDetail.entryDetail.billing.title')}</span>
            </h5>
            <Row className="gx-2 gy-3">
                <Form.Group as={Col} lg={6} controlId="email">
                    <Form.Label>{t('events.eventDetail.entryDetail.billing.emailLabel')} *</Form.Label>
                    <Form.Control
                        type="email"
                        placeholder={t('events.eventDetail.entryDetail.billing.emailPlaceholder')}
                        value={formData.email}
                        name="email"
                        className={errors?.email ? "border border-1 border-danger" : ""}
                        onChange={({ nativeEvent }) => {
                            handleFormChange("email", nativeEvent.target.value);
                        }}
                    />
                </Form.Group>

                <Form.Group as={Col} lg={6} controlId="country">
                    <Form.Label>{t('events.eventDetail.entryDetail.billing.countryLabel')} *</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder={t('events.eventDetail.entryDetail.billing.countryPlaceholder')}
                        value={formData.country}
                        name="country"
                        className={errors?.country ? "border border-1 border-danger" : ""}
                        onChange={({ nativeEvent }) => {
                            handleFormChange("country", nativeEvent.target.value);
                        }}
                    />
                </Form.Group>

                <Form.Group as={Col} lg={6} controlId="firstName">
                    <Form.Label>{t('events.eventDetail.entryDetail.billing.firstNameLabel')} *</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder={t('events.eventDetail.entryDetail.billing.firstNamePlaceholder')}
                        className={errors?.firstName ? "border border-1 border-danger" : ""}
                        value={formData.firstName}
                        name="firstName"
                        onChange={({ nativeEvent }) => {
                            handleFormChange("firstName", nativeEvent.target.value);
                        }}
                    />
                </Form.Group>

                <Form.Group as={Col} lg={6} controlId="lastName">
                    <Form.Label>{t('events.eventDetail.entryDetail.billing.lastNameLabel')} *</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder={t('events.eventDetail.entryDetail.billing.lastNamePlaceholder')}
                        value={formData.lastName}
                        className={errors?.lastName ? "border border-1 border-danger" : ""}

                        name="lastName"
                        onChange={({ nativeEvent }) => {
                            handleFormChange("lastName", nativeEvent.target.value);
                        }}
                    />
                </Form.Group>

                <Form.Group as={Col} lg={6} controlId="vat">
                    <Form.Label>{t('events.eventDetail.entryDetail.billing.vatNrLabel')}</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder={t('events.eventDetail.entryDetail.billing.vatNrPlaceholder')}
                        value={formData.nationalID}
                        name="nationalID"
                        onChange={({ nativeEvent }) => {
                            handleFormChange("nationalID", nativeEvent.target.value);
                        }}
                    />
                </Form.Group>

                <Form.Group as={Col} lg={12} controlId="street">
                    <Form.Label>{t('events.eventDetail.entryDetail.billing.streetNameLabel')} *</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder={t('events.eventDetail.entryDetail.billing.streetNamePlaceholder')}
                        value={formData.street}
                        className={errors?.street ? "border border-1 border-danger" : ""}

                        name="street"
                        onChange={({ nativeEvent }) => {
                            handleFormChange("street", nativeEvent.target.value);
                        }}
                    />
                </Form.Group>

                <Form.Group as={Col} lg={2} controlId="houseNumber">
                    <Form.Label>{t('events.eventDetail.entryDetail.billing.houseNumberLabel')} *</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder={t('events.eventDetail.entryDetail.billing.houseNumberPlaceholder')}
                        value={formData.nativeEvent}
                        name="houseNumber"
                        className={errors?.houseNumber ? "border border-1 border-danger" : ""}

                        onChange={({ nativeEvent }) => {
                            handleFormChange("houseNumber", nativeEvent.target.value);
                        }}
                    />
                </Form.Group>

                <Form.Group as={Col} lg={4} controlId="houseAdditional">
                    <Form.Label>{t('events.eventDetail.entryDetail.billing.additionLabel')}</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder={t('events.eventDetail.entryDetail.billing.additionPlaceholder')}
                        value={formData.houseAdditional}
                        name="houseAdditional"
                        onChange={({ nativeEvent }) => {
                            handleFormChange("houseAdditional", nativeEvent.target.value);
                        }}
                    />
                </Form.Group>

                <Form.Group as={Col} lg={4} controlId="postalCode">
                    <Form.Label>{t('events.eventDetail.entryDetail.billing.postalCodeLabel')} *</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder={t('events.eventDetail.entryDetail.billing.postalCodePlaceholder')}
                        value={formData.postalCode}
                        name="postalCode"
                        className={errors?.postalCode ? "border border-1 border-danger" : ""}

                        onChange={({ nativeEvent }) => {
                            handleFormChange("postalCode", nativeEvent.target.value);
                        }}
                    />
                </Form.Group>

                <Form.Group as={Col} lg={12} controlId="City">
                    <Form.Label>{t('events.eventDetail.entryDetail.billing.cityLabel')} *</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder={t('events.eventDetail.entryDetail.billing.cityPlaceholder')}
                        value={formData.city}
                        name="city"
                        className={errors?.city ? "border border-1 border-danger" : ""}

                        onChange={({ nativeEvent }) => {
                            handleFormChange("city", nativeEvent.target.value);
                        }}
                    />
                </Form.Group>

                <Form.Group as={Col} lg={6} controlId="phone">
                    <Form.Label>{t('events.eventDetail.entryDetail.billing.phoneNumberLabel')} *</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder={t('events.eventDetail.entryDetail.billing.phoneNumberPlaceholder')}
                        value={formData.phone}
                        name="phone"
                        className={errors?.phone ? "border border-1 border-danger" : ""}
                        onChange={({ nativeEvent }) => {
                            handleFormChange("phone", nativeEvent.target.value);
                        }}
                    />
                </Form.Group>

                <Col lg={6} className="mt-5">
                    <div className='d-flex'>
                        <i className='my-auto'>{t('events.eventDetail.entryDetail.billing.phoneNumberInfo')}</i>
                    </div>
                </Col>

            </Row>
        </div>
    );
};

export default BillingInformation;

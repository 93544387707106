import axios from 'axios';
const apiRoot = process.env.REACT_APP_API_ROOT;

type BillingInfo = {
  id: number;
  userID: number;
  bracketParticipantID?: number | null;
  email: string;
  country: string;
  firstName: string;
  lastName: string;
  nationalID: string;
  street: string;
  houseNumber: string;
  houseAdditional: string;
  postalCode: string;
  city: string;
  phone: string;
  dateInserted: string;
  dateUpdated?: string | null;
};

export const retrieveBillingInfo = async (data: {
  athleteUuid: string;
}): Promise<Array<BillingInfo>> => {
  try {
    const response = await axios.get(
      `${apiRoot}/user-billing/${data.athleteUuid}`
    );
    return response.data;
  } catch (e) {
    return [];
  }
};

export const postBillingInfo = async (data: BillingInfo) => {
  let response = await axios.post(`${apiRoot}/user-billing`, data);
  return response.data;
};

export const putBillingInfo = async (data: BillingInfo) => {
  let response = await axios.put(`${apiRoot}/user-billing`, data);
  return response.data;
};

import axios from 'axios';
import { time } from 'console';
import moment from 'moment';
const apiRoot = process.env.REACT_APP_API_ROOT;
const getEvents = async (academyUuid: string) => {
  let data = await (await axios.get(`${apiRoot}/events/${academyUuid}`)).data;
  return decodeEvents(data as any[]);
};

const decodeEvents = (data: any[]) => {
  return (data ?? []).reduce((result: any[], el: any) => {
    const mdate = moment(el.eventDate);
    const calendar = { month: mdate.format('MMM'), day: mdate.format('D') };
    const time = mdate.format('hh:mm A');
    const transformed = {
      id: el.eventUUID,
      calendar: calendar,
      title: el.eventName,
      type: 'Event',
      organizer: 'Default',
      private: el.private,
      time: time,
      duration: '-',
      place: el.country || "-",
      eventStarted: el.eventStarted
    };
    result.push(transformed);
    return result;
  }, []);
};

export default getEvents;

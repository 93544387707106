/* eslint-disable react/prop-types */
import React from 'react';
import { Col } from 'react-bootstrap';
import ReactCountryFlag from 'react-country-flag';
import AdminParticipantControl from '../AdminParticipantControl';
import ParticipantMatchResult from '../ParticipantMatchResult';
import { useTranslation } from 'react-i18next';
const Participant = ({
  participantData,
  textColor,
  cardColor,
  scores,
  isEnded,
  isWon,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Col
        xs={{ order: 'last' }}
        sm={{ order: 'first' }}
        lg={{ order: 'first' }}
        className="auto d-flex align-items-center flex-1 player-controll"
      >
        <div>
          {scores.winner && (
            <strong className="winner-flag">
              {t('adminControlMatch.participant.winnerByText')} {scores.by}
            </strong>
          )}
          <div className="d-flex">
            <div className="country-info d-flex flex-column">
              <span className="flag d-flex">
                <ReactCountryFlag countryCode={participantData.country} svg />
              </span>
            </div>
            <div className="user-info d-flex flex-column">
              <strong
                className="user-name d-flex text-uppercase"
                style={{ color: textColor }}
              >
                {participantData.name}
              </strong>
              <span
                className="academy-name d-block text-uppercase"
                style={{ color: textColor }}
              >
                {participantData.academy.name}
              </span>
            </div>
          </div>
          <div className="score-controll">
            {!isWon &&
              (isEnded ? (
                <ParticipantMatchResult {...rest} />
              ) : (
                <AdminParticipantControl
                  {...rest}
                  scoreCardStyle="action-holder-ibjjf"
                />
              ))}
          </div>
        </div>
      </Col>
      <Col
        xs={{ order: 'first' }}
        sm={{ order: 'last' }}
        lg={{ order: 'last' }}
        className="d-flex p-0 mx-3 score-card-poits"
      >
        <div
          className="score-box d-flex justify-content-center align-items-center mb-3"
          style={{ backgroundColor: '#3d7f2a' }}
        >
          {scores.points}
        </div>
        <div
          className="score-box d-flex justify-content-center align-items-center mb-3"
          style={{ backgroundColor: '#e8cb10' }}
        >
          {scores.advantage}
        </div>
        <div
          className="score-box d-flex justify-content-center align-items-center mb-3"
          style={{ backgroundColor: '#ac1439' }}
        >
          {scores.penalties}
        </div>
      </Col>
    </>
  );
};

export default Participant;

import axios from 'axios';
const apiRoot = process.env.REACT_APP_API_ROOT;


export const generateClientToken = async (purchaseAmount: Number) => {
  const token = `${process.env.REACT_APP_PAYPAL_CLIENT_ID
    }:${process.env.REACT_APP_PAYPAL_SECRET}`;

  const encodedToken = Buffer.from(token).toString('base64');

  //we don't want the request interceptor to add an Authorization token to this request,
  //so we're creating a new instance of axios
  const uninterceptedAxiosInstance = axios.create();

  const response = await uninterceptedAxiosInstance.post(`${process.env.REACT_APP_PAYPAL_API_URL}/v1/oauth2/token`,
    "grant_type=client_credentials",
    {
      headers: {
        Authorization: `Basic ${encodedToken}`,
      },
    });

  const clientTokenResponse = await uninterceptedAxiosInstance.post(`${process.env.REACT_APP_PAYPAL_API_URL}/v1/identity/generate-token`, null, {
    headers: {
      Authorization: `Bearer ${response.data.access_token}`,
      "Accept-Language": "en_US",
      "Content-Type": "application/json",
    },
  });

  const orderResponse = await uninterceptedAxiosInstance.post(`${process.env.REACT_APP_PAYPAL_API_URL}/v2/checkout/orders`, JSON.stringify({
    intent: "CAPTURE",
    purchase_units: [
      {
        amount: {
          currency_code: "USD",
          value: purchaseAmount
        },
      },
    ],
  }), {
    headers: {
      Authorization: `Basic ${encodedToken}`,
      "Accept-Language": "en_US",
      "Content-Type": "application/json",
    },
  });

  return {
    clientToken: clientTokenResponse.data.client_token,
    order: orderResponse.data
  };
}


export const processCardPayment = async ({
  orderId
}: {
  orderId: string;
}) => {
  const token = `${process.env.REACT_APP_PAYPAL_CLIENT_ID
    }:${process.env.REACT_APP_PAYPAL_SECRET}`;

  const encodedToken = Buffer.from(token).toString('base64');

  //we don't want the request interceptor to add an Authorization token to this request,
  //so we're creating a new instance of axios
  const unInterceptedAxiosInstance = axios.create();

  const response = await unInterceptedAxiosInstance.post(`${process.env.REACT_APP_PAYPAL_API_URL}/v2/checkout/orders/${orderId}/capture`, null, {
    headers: {
      Authorization: `Basic ${encodedToken}`,
      "Accept-Language": "en_US",
      "Content-Type": "application/json",
    },
  });

  return response.data;
}

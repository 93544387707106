import React, { useEffect, useState } from 'react';
import Hero from './Hero';
import NavbarStandard from './NavbarStandard';
//import Partners from './Partners';
//import Processes from './Processes';
import Services from './Services';
//import Testimonial from './Testimonial';
import Cta from './Cta';
import FooterStandard from './FooterStandard';
import Events from '../public-events/Events';
import getPublicEvents from 'api/events/get-public-events';
import moment from 'moment';
import { Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const Landing = () => {
  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState([]);
  const { t } = useTranslation();
  useEffect(() => {
    const loadData = data => {
      (async () => {
        setLoading(true);
        const eventsData = await getPublicEvents(data);
        setEvents(eventsData?.slice(0, 3));
        setLoading(false);
        console.log(eventsData.length);
      })();
    };
    loadData({
      upcoming: 1,
      gameType: 1,
      startDate: moment().format('yyyy/MM/DD'),
      endDate: moment().add(12, 'month').format('yyyy/MM/DD'),
      countries: []
    });
  }, []);

  return (
    <div className="home">
      <NavbarStandard />
      <Hero />
      {/*<Partners />*/}
      {/*<Processes />*/}
      <Services />

      {!loading ? (
        <Events formData={{ upcoming: 1 }} events={events} homePage={true} />
      ) : (
        <Alert variant="default" className="p-5">
          <h4 className="alert-heading">{t('publicEvents.loading')}</h4>
        </Alert>
      )}
      {/*<Testimonial />*/}
      <Cta />
      <FooterStandard />
    </div>
  );
};

export default Landing;

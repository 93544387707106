/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/react-in-jsx-scope */
import ReactCountryFlag from 'react-country-flag';
import Avatar from 'components/common/Avatar';
import award1 from 'assets/svg/award-1.svg';
import award2 from 'assets/svg/award-2.svg';
import award3 from 'assets/svg/award-3.svg';
import award4 from 'assets/svg/award-4.svg';
import 'assets/scss/theme/_bracket.scss';
import { Link } from 'react-router-dom';

// @ts-ignore
import friendlyUrl from 'friendly-url';
type PropType = {
  name: string;
  flag: string;
  academyName: string;
  medal?: string;
  winner?: string;
  avatar?: string;
  isLastRound: boolean;
  existWinner: boolean;
  type: string;
  participants: number;
  participantUUID: any;
};

const BracketParticipant = ({
  name,
  flag,
  academyName,
  winner,
  medal,
  avatar,
  isLastRound,
  existWinner,
  type,
  participants,
  participantUUID
}: PropType) => {
  return (
    <div className="participant-status row">
      <Avatar src={avatar} size="l" name={name} className="col-2" />

      <div className="user-info-holder col-9 ms-3 ">
        <div className="user-info">
          <span className="user-flag">
            {!(
              flag === 'mockFlag' ||
              name.trim().toLowerCase() === 'loser' ||
              name.trim().toLowerCase() === 'bye'
            ) ? (
              <ReactCountryFlag countryCode={flag} svg />
            ) : (
              <div
                className="bg bg-primary rounded m-1"
                style={{ width: 15, height: 10 }}
              ></div>
            )}
          </span>
          <Link to={`/athlete/${friendlyUrl(name)}/${participantUUID}`}>
            <span className="user-name text-truncate">{name}</span>
          </Link>
          {winner ? (
            <span className="winner-flag badge rounded-pill badge-soft-primary">
              W
            </span>
          ) : (
            ''
          )}
          {isLastRound && existWinner && name.trim().toLowerCase() != 'bye' ? (
            type === 'final' ? (
              winner ? (
                <img className="ms-2" src={award1} height={25} width={25}></img>
              ) : (
                <img className="ms-2" src={award2} height={25} width={25}></img>
              )
            ) : type === 'bronze' ? (
              winner ? (
                participants === 3 ? (
                  ''
                ) : (
                  <img
                    className="ms-2"
                    src={award3}
                    height={25}
                    width={25}
                  ></img>
                )
              ) : participants === 3 ? (
                <img className="ms-2" src={award3} height={25} width={25}></img>
              ) : (
                <img className="ms-2" src={award4} height={25} width={25}></img>
              )
            ) : (
              ''
            )
          ) : (
            ''
          )}
        </div>
        <span className="academy-name">{academyName}</span>
        {medal ? <span className="medal">{medal}</span> : ''}
      </div>
    </div>
  );
};
export default BracketParticipant;

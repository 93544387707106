import { useEffect, useState } from 'react';
import { Alert, Container, Navbar, Row } from 'react-bootstrap';

import { useParams, Link } from 'react-router-dom';
import Clock from 'react-live-clock';

import { useTranslation } from 'react-i18next';
import getPublicEventDetail from 'api/events/get-public-event-detail';
import { MatSchedules } from 'components/dashboards/events/event-detail/MatScheduleDetail';
import getEventDetail from 'api/events/get-event';


const TvModePublicMatDetail = () => {
  const { t } = useTranslation();

  const { eventId, academyId, matId, isPublic } = useParams();
  const [eventInfo, setEventInfo] = useState(null);
  const [loading, isLoading] = useState(true);


  const loadData = () => {
    (async () => {
      let data = null;

      if (Number(isPublic)) {
        data = await getPublicEventDetail(eventId);
      } else {
        data = await getEventDetail(academyId, eventId);
      }
      setEventInfo(data);

      isLoading(false);
    })();
  };

  useEffect(() => {
    loadData();
    const REFRESH_INTERVAL_RATE = 1 * 60 * 1000;
    const intervalId = setInterval(loadData, REFRESH_INTERVAL_RATE);
    return () => {
      clearInterval(intervalId);
    };
  }, [eventId]);

  return (
    <div className="bg-dark vh-100 p-5 bgPublic-Mat-Detail ">
      <Row className="justify-content-center align-items-center">
        {loading && (
          <Alert variant="default" className="p-5">
            <h4 className="alert-heading">{t('publicEvents.loading')}</h4>
          </Alert>
        )}

        {eventInfo && (
          <>
            <Container className="justify-content-between d-flex">
              <Navbar.Brand
                className="text-white dark__text-white"
                as={Link}
                to="/"
              >
                SLICKCOMP
              </Navbar.Brand>
              <div>
                <Clock format={'HH:mm:ss'} ticking={true} />
              </div>
            </Container>

            <MatSchedules
              eventInfo={eventInfo}
              academyId={academyId}
              eventId={eventId}
              matId={matId}
              isTVMode={true}
              isPublic={!!Number(isPublic)}
            />

          </>
        )}
      </Row>
    </div>
  );
};

export default TvModePublicMatDetail;

import { compact, filter, first, includes, map, orderBy, } from "lodash";
import moment from "moment";
import { useMemo } from "react";

export const useTopBrackets = ({ eventInfo, matId, bracketId = "" }) => {
    const matBrackets = eventInfo
        ? orderBy(
            filter(
                eventInfo.brackets,
                bracket => Number(bracket.mat) === Number(matId)
            ),
            bracket => bracket.order || 0
        )
        : [];

    const bracketCurrentlyInProgress = useMemo(() => {
        const sortedBracketsInStart = orderBy(
            filter(matBrackets, b => b.startTime != null && b.endTime == null),
            o => moment(o.startTime).format(),
            ['desc']
        );
        return first(sortedBracketsInStart);
    }, [matBrackets]);

    const topTwoBrackets = useMemo(() => {
        const sortedBracketsInEnd = orderBy(
            filter(matBrackets, b => b.startTime != null && b.endTime != null),
            o => moment(o.endTime).format(),
            ['desc']
        );

        //get the top 2 Brackets - 1. The last finished bracket... 2. the Current Bracket in Play
        return compact([first(sortedBracketsInEnd), bracketCurrentlyInProgress]);
    }, [matBrackets]);

    const bracketsWithoutTopTwo = useMemo(() => {
        return filter(matBrackets, (bracket) => {
            return !includes(map(topTwoBrackets, m => m.bracketUUID), bracket.bracketUUID)
        })
    }, [matBrackets]);


    const bracketsUpcomingMatchesUUID = useMemo(() => {
        return compact([bracketCurrentlyInProgress?.bracketUUID, first(bracketsWithoutTopTwo)?.bracketUUID])
    }, [])

    const canWeDisplayBracketUpcomingMatches = includes(bracketsUpcomingMatchesUUID, bracketId)

    return { topTwoBrackets, matBrackets, bracketsWithoutTopTwo, bracketsUpcomingMatchesUUID, canWeDisplayBracketUpcomingMatches }
}

import React, {  useState } from 'react';
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FalconCloseButton from 'components/common/FalconCloseButton';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const TableSearchMatches = ({ Data, setData, type }) => {
  const [searchInputValue, setSearchInputValue] = useState('');

  const { t } = useTranslation();
  const filterData =  value => {
    if(value.trim()!=''){
   const dataFilter = Data.filter((item)=> {
   const keyValues = value.split(' ');
   const objectiveWords = item.participant1?.name + ' '+
                          item.participant2?.name + ' '+
                          item.participant1?.country + ' '+
                          item.participant2?.country + ' '+
                          item.matchGender + ' '+
                          item.matchId + ' '+
                          item.matchType + ' '+
                          item.seniority + ' '+
                          item.weight + ' '+
                          item.academy.name + ' '+
                          moment(item.matchDatetime).format('MM-DD-YYYY')+ ' '+
                          moment(item.matchEndtime).format('MM-DD-YYYY');

   var existKey = keyValues?.map((data)=>  data == ''? false: objectiveWords?.trim().toLowerCase().includes(data.trim().toLowerCase()));
   return existKey?.includes(true);
   }
   );
   
   setData(dataFilter);
  }

  };
  const search = async target => {
    setSearchInputValue(target.value);
    filterData(target.value);
  };
  return (
    <Form className="position-relative search-box">
      <Form.Control
        type="search"
        placeholder={t('searchText')}
        aria-label="Search"
        className="rounded-pill search-input"
        value={searchInputValue}
        onChange={({ target }) => {
          search(target);
        }}
      />
      <FontAwesomeIcon
        icon="search"
        className="position-absolute text-400 search-box-icon"
      />
      {searchInputValue && (
        <div
          className="position-absolute"
          style={{ right: '10px', top: '8px' }}
        >
          <FalconCloseButton
            size="sm"
            noOutline
            onClick={() => {
              setSearchInputValue('');
            }}
          />
        </div>
      )}
    </Form>
  );
};
export default TableSearchMatches;

import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  Dropdown,
  Image,
  Modal,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';
import team3 from 'assets/img/team/3.jpg';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import avatarError from 'assets/img/team/avatar.png';
import Flex from 'components/common/Flex';
import deleteBracketParticipant from 'api/events/delete-bracket-participant';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import friendlyUrl from 'friendly-url';
import './participants-avatar.css';

const mapStateToProp = state => ({
  academyId: state.auth.academyId,
  isAcademy: state.auth.isAcademy,
  uuid: state.auth.uuid
});

const ParticipantAvatar = ({
  avatar,
  name,
  tooltip,
  id,
  setRefetch,
  refetch,
  isAcademy,
  participantUUID,
  isCreatorAcademy
}) => {
  const [show, setShow] = useState(false);

  const { t } = useTranslation();
  const { academyId, bracketUUID } = useParams();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleDeleteParticipant = async () => {
    setShow(false);
    const isDeleted = await deleteBracketParticipant({
      bracketUUID: bracketUUID,
      academyId: Number(academyId),
      userId: Number(id.trim())
    });

    if (isDeleted.result) {
      setRefetch(!refetch);
      toast.success(t('brackets.successfullyRemoved'));
    } else {
      toast.error(isDeleted.message);
    }
  };

  return (
    <>
      <Dropdown>
        <Dropdown.Toggle bsPrefix="toggle" to="#" as={Link}>
          <OverlayTrigger
            overlay={
              <Tooltip title={name}>
                <FontAwesomeIcon
                  icon="crown"
                  className="me-1"
                  cursor={'pointer'}
                />
                <span>{name}</span>
              </Tooltip>
            }
          >
            <div style={{ width: '55px', height: '55px' }}>
              <Image
                thumbnail
                fluid
                roundedCircle
                className="mb-3 shadow-sm participant-avatar-img"
                src={avatar}
                width={55}
                height={55}
                style={{ objectFit: 'cover', width: '100%', height: '100%' }}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = avatarError;
                }}
              />
            </div>
          </OverlayTrigger>
        </Dropdown.Toggle>

        <Dropdown.Menu className="dropdown-menu-card  dropdown-menu-end">
          <Dropdown.Item
            className="fw-bold text-dark border-bottom"
            to={`/athlete/${friendlyUrl(name)}/${participantUUID}`}
            as={Link}
          >
            <Flex direction={'column'} alignItems="center">
            <Link
                to={`/athlete/${friendlyUrl(name)}/${participantUUID}`}
                style={{ color: '#ffffff', textAlign: 'center' }}
              >
              <Image
                thumbnail
                fluid
                roundedCircle
                className="mb-3 shadow-sm"
                src={avatar}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = avatarError;
                }}
                width={50}
                height={50}
              />
              <span>{name}</span>
            </Link>
            </Flex>
          </Dropdown.Item>

          {isCreatorAcademy && (
            <Dropdown.Item
              as={Button}
              onClick={() => {
                handleShow();
              }}
            >
              <FontAwesomeIcon
                icon={['fa', 'trash']}
                className="me-2 align-self-start text-danger"
                cursor={'pointer'}
              />

              <span className="text-dark">{t('brackets.deleteParticipant')}</span>
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
      <Modal show={show} onHide={handleClose}>
        <div className="d-flex justify-content-end m-3">
          <FontAwesomeIcon icon="xmark" />
        </div>
        <Modal.Body>
          <h5>{t('deleteParticipantModal.question')}</h5>
          <p>{t('deleteParticipantModal.description')}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t('deleteParticipantModal.option1')}
          </Button>
          <Button variant="primary" onClick={() => handleDeleteParticipant()}>
            {t('deleteParticipantModal.option2')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default connect(mapStateToProp)(ParticipantAvatar);

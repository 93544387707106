import Section from 'components/common/Section';
import { Row } from 'react-bootstrap';
import Participant from './Participant';
const MatchResultIBJJF = ({ data, getParticipantData }) => {
  return (
    <>
      <Section className="score-card light d-flex add">
        <div className="holder d-flex flex-column flex-1">
          {data && (
            <>
              <Row
                className="justify-content-between flex-1 m-0"
                style={{ backgroundColor: '#FFFFFF' }}
              >
                <Participant
                  participantData={getParticipantData(data[0].matchWinner)}
                  winner={true}
                  textColor="#000000"
                  result={JSON.parse(data[0].result).winner}
                  method={JSON.parse(data[0].result).method}
                  matchWinnerUUID={data[0]?.matchWinnerUUID}
                  matchLoserUUID={data[0]?.matchLoserUUID}
                />
              </Row>
              <Row
                className="justify-content-between flex-1 m-0"
                style={{ backgroundColor: '#1c5cbc' }}
              >
                <Participant
                  participantData={getParticipantData(data[0].matchLoser)}
                  winner={false}
                  textColor="#FFFFFF"
                  result={JSON.parse(data[0].result).loser}
                  method={JSON.parse(data[0].result).method}
                  matchWinnerUUID={data[0]?.matchWinnerUUID}
                  matchLoserUUID={data[0]?.matchLoserUUID}
                />
              </Row>
            </>
          )}
        </div>
      </Section>
    </>
  );
};

export default MatchResultIBJJF;

import axios from 'axios';
const apiRoot = process.env.REACT_APP_API_ROOT;

type CancelSubscriptionArgs = {
  academyId: number;
  planId: number;
  price: number;
  periodicity: string;
  paymentInfo: any;
};
const cancelSubscriptionPlan = async ({
  academyId,
  paymentInfo,
  periodicity,
  planId,
  price
}: CancelSubscriptionArgs) => {
  const payment = paymentInfo ? JSON.parse(paymentInfo) : {};
  if (payment?.payPal_info?.subscriptionID) {
    const token = `${
      'AaYeWr8B3t8hp-MFTIZTTVT18i7rFYQmq_-rp3tlDcwAjsWhHWzIEGZeWq5T8t_Nttkdy8GTVTr50U6V' ||
      process.env.REACT_APP_PAYPAL_CLIENT_ID
    }:${process.env.REACT_APP_PAYPAL_SECRET}`;
    
    const encodedToken = Buffer.from(token).toString('base64');

    //we don't want the request interceptor to add an Authorization token to this request,
    //so we're creating a new instance of axios
    const uninterceptedAxiosInstance = axios.create();

    try {
      //DOCS: https://developer.paypal.com/docs/api/subscriptions/v1/#subscriptions_cancel
      const paypalResponse = await uninterceptedAxiosInstance.post(
        `${process.env.REACT_APP_PAYPAL_API_URL}/v1/billing/subscriptions/${payment?.payPal_info?.subscriptionID}/cancel`,
        {
          reason: 'No'
        },
        {
          headers: {
            Authorization: `Basic ${encodedToken}`
          }
        }
      );

      //204 is the success code returned by paypal
      //if the Paypal cancel request isn't successful, throw an error that'd be caught by the caller
      if (paypalResponse.status != 204) {
        throw new Error('error cancelling paypal');
      }
    } catch (error) {}
  }

  let response = await axios.put(`${apiRoot}/academy-subscriptions/`, {
    action: 'cancel',
    academyID: academyId,
    paymentInfo: {},
    periodicity: periodicity,
    price: price,
    planID: planId
  });
  return response.data;
};
export default cancelSubscriptionPlan;

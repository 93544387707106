import { useState, useRef, useEffect, memo } from "react";
import {
    PayPalHostedFieldsProvider,
    PayPalHostedField,
    usePayPalHostedFields,
} from "@paypal/react-paypal-js";
import { useTranslation } from "react-i18next";
import { Form, Button } from "react-bootstrap";
import { toast } from 'react-toastify';

import { processCardPayment } from "api/events/payment/client-token";

const INVALID_COLOR = {
    color: "#dc3545",
};

const SubmitPayment = ({
    handleCompletePayment,
    existingEntryBracket = null,
    existingEntryId = null,
}) => {
    const [paying, setPaying] = useState(false);
    const cardHolderName = useRef(null);
    const hostedField = usePayPalHostedFields();

    const { t } = useTranslation();

    const handleClick = () => {
        if (!hostedField?.cardFields) {
            const childErrorMessage = 'Unable to find any child components in the <PayPalHostedFieldsProvider />';

            action(ERROR)(childErrorMessage);
            throw new Error(childErrorMessage);
        }
        const isFormInvalid =
            Object.values(hostedField.cardFields.getState().fields).some(
                (field) => !field.isValid
            ) || !cardHolderName?.current?.value;

        if (isFormInvalid) {
            return alert(
                "The payment form is invalid"
            );
        }
        setPaying(true);
        hostedField.cardFields
            .submit({
                cardholderName: cardHolderName?.current?.value,
            })
            .then(async (data) => {

                try {
                    setPaying(true);
                    const paymentResponse = await processCardPayment(data);

                    if (paymentResponse.status === 'DECLINED') {
                        toast.error(t('events.eventDetail.entryDetail.paymentErrorToast'), {
                            autoClose: 5000,
                        });
                    }
                    else {
                        await handleCompletePayment(paymentResponse, existingEntryBracket, existingEntryId);
                    }
                }

                catch (error) {

                } finally {
                    setPaying(false);
                }


            })

    };

    return (
        <>
            <Form.Group className="mb-3">
                <Form.Label className="ls text-uppercase text-600 fw-semi-bold mb-0">
                    {t("events.eventDetail.entryDetail.billing.card.cardHolderName")}
                </Form.Label>
                <Form.Control
                    type="text"
                    id="card-holder"
                    className="card-payment-field"
                    ref={cardHolderName}
                    placeholder={t("events.eventDetail.entryDetail.billing.card.cardHolderName")}
                />
            </Form.Group>


            <Button
                variant="primary"
                type="button"
                onClick={handleClick}
                disabled={paying}
                className="mt-3 px-5 py-3 mx-auto"
            >
                {paying
                    ? t(
                        'events.eventDetail.entryDetail.paymentButtonProcessingLabel'
                    )
                    : t('events.eventDetail.entryDetail.billing.card.completePayment')
                }
            </Button>

        </>
    );
};




export const PaymentCardFormInner = (props) => {

    const { handleCompletePayment, paypalCardOrderDataId, existingEntryBracket = null, existingEntryId = null, } = props;

    const { t } = useTranslation();

    return (
        <>
            <PayPalHostedFieldsProvider
                notEligibleError={<div>Not eligible</div>}
                styles={{ ".valid": { "color": "#28a745" }, ".invalid": { "color": "#dc3545" }, "input": { "font-size": "16px" } }}
                createOrder={() => {
                    return paypalCardOrderDataId
                }}
            >
                <Form.Label className="ls text-uppercase text-600 fw-semi-bold mb-0">
                    {t("events.eventDetail.entryDetail.billing.card.cardNumber")}
                    <span style={INVALID_COLOR}>*</span>
                </Form.Label>

                <PayPalHostedField
                    id="card-number"
                    className="form-control card-payment-field"
                    hostedFieldType="number"

                    options={{
                        selector: "#card-number",
                        placeholder: t("events.eventDetail.entryDetail.billing.card.cardNumber"),
                    }}
                />

                <Form.Label className="ls text-uppercase text-600 fw-semi-bold mb-0">
                    {t("events.eventDetail.entryDetail.billing.card.CVV")}
                    <span style={INVALID_COLOR}>*</span>
                </Form.Label>
                <PayPalHostedField
                    id="cvv"
                    className="form-control card-payment-field"
                    hostedFieldType="cvv"
                    options={{
                        selector: "#cvv",
                        maskInput: true,
                        placeholder: t("events.eventDetail.entryDetail.billing.card.CVV")
                    }}
                />
                <Form.Label className="ls text-uppercase text-600 fw-semi-bold mb-0">
                    {t("events.eventDetail.entryDetail.billing.card.expirationDate")}
                    <span style={INVALID_COLOR}>*</span>
                </Form.Label>
                <PayPalHostedField
                    id="expiration-date"
                    className="form-control card-payment-field"
                    hostedFieldType="expirationDate"
                    options={{
                        selector: "#expiration-date",
                        placeholder: "MM/YYYY",
                    }}
                />
                <SubmitPayment handleCompletePayment={handleCompletePayment} existingEntryBracket={existingEntryBracket} existingEntryId={existingEntryId} />

            </PayPalHostedFieldsProvider>
        </>
    )
}

export const PaymentCardForm = memo(PaymentCardFormInner, (prevProps, nextProps) => (prevProps.paypalCardOrderDataId === nextProps.paypalCardOrderDataId))

import axios from 'axios';
const apiRoot = process.env.REACT_APP_API_ROOT;

type BracketParticipantData = {
  event_id: string;
  academy_id: string;
  gender: string;
  giNogi: string;
  seniority: string;
  weight: string;
  event_entry_id: string;
  user_id: string;
  confirmed: number;
};

const postBracketParticipant = async (bracketData: BracketParticipantData) => {
  let response = await axios.post(
    `${apiRoot}/bracket_participant`,
    bracketData
  );
  return response;
};

export const putBracketParticipant = async (bracketData: BracketParticipantData) => {
  let response = await axios.put(
    `${apiRoot}/bracket_participant`,
    bracketData
  );
  return response.data;
};

export default postBracketParticipant;

/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';
import { getMatchById, putMatch } from 'api/match';
import MatchAdmin from 'api/admin/match/MatchAdmin';
import ScoreCardDefault from 'components/pages/score-card/score-card-default/ScoreCardDefault';
import ScoreCardIBJJF from 'components/pages/score-card/score-card-ibjjf/ScoreCardIBJJF';

import { connect } from 'react-redux';

const mapStateToProp = state => ({
  match: {
    params: {
      academyId: state.auth.matchInfo.matchAcademyId,
      matchid: state.auth.matchInfo.matchId,
      connectionId: state.auth.matchInfo.matchConnectionId,
    }
  }
});


const ScoreCard = ({ match }) => {

  const [participant1Score, setP1score] = useState({
    advantage: 0,
    penalties: 0,
    points: 0
  });
  const [participant2Score, setP2score] = useState({
    advantage: 0,
    penalties: 0,
    points: 0
  });

  const [time, setTime] = useState(300);
  const [data, setData] = useState(null);
  const [matchAdmin] = useState(new MatchAdmin(match.params.matchid));

  useEffect(() => {
    (async () => {
      const response = await getMatchById(
        match.params.matchid,
        match.params.academyId
      );  
      setData(response);

      var formatDuration = response.duration?.split(':');
      setTime(formatDuration?.length > 1 ? Number(formatDuration[0]) * 60 : Number(response.duration || 300));

      matchAdmin.onConnection(connectionId =>
        handleConnection(response, connectionId)
      );
    })();
  }, []);

  const handleConnection = (data, connectionId) => {
    putMatch(data, match.params.connectionId, connectionId);
    matchAdmin.ready();
  };


  return (
    <>
      {data?.scoreboardType === 1 || data?.scoreboardType === null ?
        <ScoreCardDefault
          data={data}
          matchAdmin={matchAdmin}
          setP1score={setP1score}
          setP2score={setP2score}
          participant1={participant1Score}
          participant2={participant2Score}
          setTime={setTime}
          time={time} />
        :
        data?.scoreboardType === 2 ?
          <ScoreCardIBJJF
            data={data}
            matchAdmin={matchAdmin}
            setP1score={setP1score}
            setP2score={setP2score}
            participant1={participant1Score}
            participant2={participant2Score}
            setTime={setTime}
            time={time} />
          :
          ""
      }
    </>
  );
};

export default connect(mapStateToProp)(ScoreCard);


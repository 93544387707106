import { useState, useEffect } from 'react';
import { Col, Row, Alert, Form, Button, Card } from 'react-bootstrap';
import EntryRegistration from './EntryRegistration';
import PaymentMethod from './PaymentMethod';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js';

import { useHistory } from 'react-router-dom';

import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import getEventEntryDetail from 'api/events/get-event-entry';
import UserRegistrations from './UserRegistrations';
import postBracketParticipant, {
  putBracketParticipant
} from 'api/events/post-bracket-participant';
import { sanitizePaypalResponse } from 'constants/paypal';
import moment from 'moment';
import { PaymentCardForm } from './PaymentCardForm';
import { generateClientToken } from 'api/events/payment/client-token';
import { putBillingInfo } from 'api/events/billing-info';
import getAcademy from 'api/academy/get-academy';
import { BillingInformationSection } from './BillingInformation';

const mapStateToProp = state => ({
  academyId: state.auth.academyId,
  uuid: state.auth.uuid,
  userId: state.auth.userId,
  entryInfo: {
    entryAcademyId: state.auth.eventEntryRegisterInfo.entryAcademyId,
    eventId: state.auth.eventEntryRegisterInfo.eventId,
    entryId: state.auth.eventEntryRegisterInfo.entryId,
    eventUUID: state.auth.eventEntryRegisterInfo.eventUUID,
  }
});


const EntryDetail = ({ academyId: currentUserAcademyId, uuid, userId, entryInfo: { entryAcademyId, eventId, entryId, eventUUID } }) => {

  const history = useHistory();
  const { t } = useTranslation();
  const { status } = useParams();
  const [eventEntryInfo, setEventEntryInfo] = useState(null);
  const [eventUserRegistrations, setEventUserRegistrations] = useState([]);
  const [academyDataInfo, setAcademyDataInfo] = useState(null);
  const [loading, isLoading] = useState(true);
  const [errors, setErrors] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [displayPayment, setDisplayPayment] = useState(false);
  const [selectedBracket, setSelectedBracket] = useState({});

  const [refreshData, setRefreshData] = useState(false);
  const [refreshRegistrationsData, setRefreshRegistrationsData] = useState(false); //used to refresh the user-registrations sidebar section on registration completion

  const [paymentMethod, setPaymentMethod] = useState('');
  const [allowPaypalCardPayment, setAllowPaypalCardPayment] = useState(false);
  const [loadingCardToken, setLoadingCardToken] = useState(true);
  const [paypalCardOrderData, setPaypalCardOrderData] = useState(null);
  const [selectedBillingDataId, setSelectedBillingDataId] = useState("");
  const [billingInfoList, setBillingInfoList] = useState([]);

  const [{ options: payPalOptions }, payPalDispatch] = usePayPalScriptReducer();

  const academyId = entryAcademyId ?? currentUserAcademyId;

  const [formData, setFormData] = useState({
    belt: '',
    gender: '',
    seniority: '',
    weight: '',
    email: '',
    country: '',
    firstName: '',
    lastName: '',
    companyName: '',
    vatNr: '',
    streetName: '',
    houseNumber: '',
    addition: '',
    postalCode: '',
    city: '',
    phoneNumber: ''
  });

  const registerFormSchema = yup.object().shape({
    belt: yup.string().required(),
    gender: yup.string().required(),
    seniority: yup.string().required(),
    weight: yup.string().required()
  });

  const getEventAndAcademyData = async () => {
    const [eventData, [academyInfo]] = await Promise.all([
      getEventEntryDetail({
        status,
        eventId,
        eventEntryId: entryId,
        eventUUID: eventUUID,
        userId: academyId,
        userUUID: uuid
      }),
      getAcademy(academyId)
    ]);

    return {
      eventData,
      academyInfo,
    }
  }

  const loadUserRegistrationsData = () => {

    (async () => {

      try {
        const { eventData } = await getEventAndAcademyData();
        setEventUserRegistrations(eventData?.userRegistrations ?? []);
      } finally {
        setRefreshRegistrationsData(false);
      }

    })();

  }

  const loadData = () => {
    (async () => {
      try {

        const { academyInfo, eventData } = await getEventAndAcademyData();

        //if the academy has a default payment set, then default the payment method to 'default-payment', if not, then default to 'paypal'
        if (!paymentMethod) {
          setPaymentMethod(academyInfo?.defaultPaymentInfo ? 'default-payment' : 'paypal');
        }

        setAllowPaypalCardPayment(academyInfo?.paypalAdvancedPayment == "1"); //if this is set to 1, then allow the user to pay with card

        setEventEntryInfo(eventData);
        setAcademyDataInfo(academyInfo);
        setEventUserRegistrations(eventData?.userRegistrations ?? []);
      } finally {
        isLoading(false);
        setRefreshData(false);
      }

    })();
  };

  const handleFormChange = (inputKey, inputValue) => {
    const updatedFormData = { ...formData, [inputKey]: inputValue };
    setFormData(updatedFormData);
  };

  const emptySelectFieldsWhenGenderChanges = () => {
    const updatedFormData = {
      ...formData,
      seniority: '',
      belt: '',
      weight: ''
    };
    setFormData(updatedFormData);
  };

  const handleCompletePayment = async (paymentDetails = {}, existingEntryBracket = null, existingEntryId = null, paymentConfirmed = 1) => {
    try {
      setProcessing(true);

      const selectedBillingDetails = billingInfoList.find(m => Number(m.id) === Number(selectedBillingDataId)) ?? {};

      const paymentBracketId = existingEntryBracket || selectedBracket.bracketId;
      const paymentEventEntryId = existingEntryId ? Number(existingEntryId) : Number(entryId);
      const paymentUserId = Number(userId);
      const paymentAcademyId = Number(academyId);
      const paymentEventId = Number(eventId);

      const result = await putBracketParticipant({
        confirmed: paymentConfirmed,
        bracket_id: paymentBracketId,
        academy_id: paymentAcademyId,
        event_entry_id: paymentEventEntryId,
        event_id: paymentEventId,
        user_id: paymentUserId,
        payment_info: {
          payment_method: paymentMethod,
          payPal_info: sanitizePaypalResponse(paymentDetails),
        }
      });

      const bracketParticipant = (eventEntryInfo?.userRegistrations || []).find(m => m.bracket_id === paymentBracketId
        && m.user_id === paymentUserId
        && m.event_entry_id === paymentEventEntryId
        && m.academy_id === paymentAcademyId && m["event.id"] === paymentEventId);


      //also update Billing Details, if the user has selected a billing address
      if (selectedBillingDetails && selectedBillingDataId) {

        if (bracketParticipant) {
          const bracketParticipantID = bracketParticipant["bracket_participant.id"];
          try {
            await putBillingInfo({
              ...selectedBillingDetails,
              billingID: selectedBillingDataId,
              bracketParticipantID
            })
          } catch (error) {
          }
        }
      }


      if (result.statusCode === 200) {
        toast.success(t('events.eventDetail.entryDetail.paymentSuccessToast'), {
          autoClose: 2000
        });

        history.push('/dashboard/profile');

        setDisplayPayment(true);
      } else {
        toast.error(result.body, {
          autoClose: 3000,
        });

        setProcessing(false);
      }
    } catch (e) {
      console.log(e);
      setProcessing(false);
      toast.error(t('events.eventDetail.entryDetail.paymentErrorToast'), {
        autoClose: 3000
      });
    }
  };

  const handleFormSubmit = event => {
    event.preventDefault();

    const bracket = eventEntryInfo.eventBrackets.find(
      m =>
        m.gender === formData.gender &&
        m.seniority === formData.seniority &&
        m.weight === formData.weight &&
        m.belt === formData.belt
    );

    (registerFormSchema)
      .validate(formData, { abortEarly: false })
      .then(async () => {
        setErrors({});

        try {
          if (!bracket) {
            toast.error(
              t('events.eventDetail.entryDetail.bracketNotFoundError'),
              { autoClose: 2000 }
            );
            return;
          }

          if (!selectedBillingDataId && paymentMethod === 'card') {
            toast.error(
              t('events.eventDetail.entryDetail.billing.card.billingAddressNotSelected'),
              { autoClose: 2000 }
            );
            return;
          }

          setProcessing(true);
          setSelectedBracket(bracket);

          const result = await postBracketParticipant({
            academy_id: Number(academyId),
            confirmed: Number(eventEntryInfo.entry.price) ? 0 : 1, //when entry is free, auto confirm the registration
            event_entry_id: Number(entryId),
            event_id: Number(eventId),
            gender: formData.gender,
            gi_nogi: bracket.gi_nogi,
            seniority: formData.seniority,
            user_id: userId,
            weight: formData.weight,
            belt: formData.belt,
            dateTime: moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
          });

          if (result.data.statusCode != 400) {
            //Display Different message if the entry is free..
            toast.success(
              Number(eventEntryInfo.entry.price)
                ? t('events.eventDetail.entryDetail.registrationSuccessToast')
                : t(
                  'events.eventDetail.entryDetail.freeRegistrationSuccessToast'
                ),
              { autoClose: 2000 }
            );

            //if the price is free..
            //get to the user profile..
            if (!Number(eventEntryInfo.entry.price)) {
              history.push('/dashboard/profile');
              return;
            }

            setDisplayPayment(true);
            setRefreshRegistrationsData(true);
            setProcessing(false);

          } else {
            //if the response from the api is about a 'Duplicate Entry'
            if ((result.data.body || '').includes('Duplicate entry')) {
              toast.error(
                t('events.eventDetail.entryDetail.duplicateBracketErrorToast'),
                { autoClose: 2000 }
              );
            } else {
              toast.error(result.data.body);
            }

            setTimeout(() => {
              toast.dismiss();
            }, 2000);

            setProcessing(false);
          }
        } catch (e) {
          setProcessing(false);
          toast.error(
            t('events.eventDetail.entryDetail.registrationErrorToast')
          );
          setTimeout(() => {
            toast.dismiss();
          }, 2000);
        }
      })
      .catch(err => {
        if (err.name === 'ValidationError') {
          let errs = {};
          err.inner.flatMap(e => {
            errs[e.path] = e.errors;
          });
          setErrors(errs);
        }
      });
  };

  useEffect(() => {
    loadData();
    payPalDispatch({
      type: 'setLoadingStatus',
      value: 'pending'
    });
  }, [eventId, entryId, refreshData]);

  useEffect(() => {
    loadUserRegistrationsData();
  }, [refreshRegistrationsData]);

  useEffect(() => {
    (async () => {

      if (paymentMethod === 'card' && eventEntryInfo?.entry?.price) {
        setLoadingCardToken(true);

        try {
          const { clientToken, order } = await generateClientToken(eventEntryInfo.entry.price);
          setPaypalCardOrderData(order);


          payPalDispatch({
            type: "resetOptions",
            value: {
              ...payPalOptions,
              "data-client-token": clientToken,
              intent: "capture",
              vault: false,
            },
          });

        } finally {
          setLoadingCardToken(false);
        }
      }

    })();
  }, [paymentMethod]);


  const PaymentButton = ({
    existingEntryBracket = null,
    existingEntryId = null,
  }) => {

    return (
      <>
        <PayPalButtons
          fundingSource={"paypal"}
          createOrder={(data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    value: eventEntryInfo.entry.price
                  }
                }
              ]
            });
          }}
          onApprove={(data, actions) => {
            return actions.order.capture().then(details => {
              handleCompletePayment(details, existingEntryBracket, existingEntryId);
            });
          }}
        />
      </>
    )
  }


  const PaymentIntegration = (props) => {

    if (props.paymentFundingSource === 'paypal') {
      return <PaymentButton {...props} />
    }

    if (paymentMethod === "default-payment") {
      return (
        <Col
          md={12}
          className="ps-xxl-5 text-center text-md-start text-xl-center text-xxl-start d-flex justify-content-center mt-2 mb-3"
        >
          <Button
            onClick={() => {
              handleCompletePayment({}, null, null, 0); //payments made with bank transfer can only be confirmed by the Academies
            }}
            disabled={processing}
            className="mt-3 px-5 py-3 mx-auto"
          >
            {processing ? t('events.eventDetail.entryDetail.paymentButtonProcessingLabel')
              : t('events.eventDetail.entryDetail.defaultPaymentFinishRegistrationButtonLabel')}
          </Button>
        </Col>
      )
    }

    if (loadingCardToken) {
      return (
        <span>
          {t("publicEvents.loading")}
        </span>)
    }

    return null;

  }

  return (
    <>
      {loading && (
        <Alert variant="default" className="mb-0 rounded-0">
          <h4 className="alert-heading">{t('events.eventDetail.loading')}</h4>
        </Alert>
      )}
      {eventEntryInfo && (
        <Form onSubmit={handleFormSubmit}>
          <Row className="g-3">
            <Col xl={{ span: 4, order: 1 }}>
              <UserRegistrations
                userRegistrations={eventUserRegistrations}
                paymentButton={PaymentButton}
                handleCompletePayment={handleCompletePayment}
              />
            </Col>
            <Col xl={8}>
              <EntryRegistration
                entryInfo={eventEntryInfo}
                formData={formData}
                handleFormChange={handleFormChange}
                emptySelectFieldsWhenGenderChanges={
                  emptySelectFieldsWhenGenderChanges
                }
                errors={errors}
              />

              <BillingInformationSection
                selectedBillingDataId={selectedBillingDataId}
                setSelectedBillingDataId={setSelectedBillingDataId}
                billingInfoList={billingInfoList}
                setBillingInfoList={setBillingInfoList}
                entryInfo={eventEntryInfo} />

              <Card>
                <PaymentMethod
                  entryInfo={eventEntryInfo}
                  academyInfo={academyDataInfo}
                  formData={formData}
                  selectedBillingDataId={selectedBillingDataId}
                  setSelectedBillingDataId={setSelectedBillingDataId}
                  billingInfoList={billingInfoList}
                  setBillingInfoList={setBillingInfoList}
                  handleFormChange={handleFormChange}
                  errors={errors}
                  processing={processing}
                  PaymentButton={PaymentIntegration}
                  displayPayment={displayPayment}
                  allowPaypalCardPayment={allowPaypalCardPayment}
                  paymentMethod={paymentMethod}
                  setPaymentMethod={setPaymentMethod}
                />

                {/* Render Card Form separately  */}
                {/* We're doing this because multiple renders break the Paypal Card integration, so we only want to render this once */}
                {displayPayment && !loadingCardToken && paypalCardOrderData && paymentMethod === "card" && (
                  <Card.Body className="entry-payment-card-container">
                    <Row>
                      <PaymentCardForm
                        paypalCardOrderDataId={paypalCardOrderData?.id || ""}
                        handleCompletePayment={handleCompletePayment}
                        amount={eventEntryInfo.entry.price} />
                    </Row>
                  </Card.Body>
                )}
              </Card>


            </Col>
          </Row>
        </Form>
      )}
    </>
  );
};

export default connect(mapStateToProp)(EntryDetail);

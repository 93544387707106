/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable */
import { useState } from 'react';
import {
  Bracket,
  Seed,
  SeedItem,
  SeedTeam,
  IRoundProps,
  IRenderSeedProps
} from 'react-brackets';
import { OverlayTrigger, Popover, Button, Modal } from 'react-bootstrap';
import BracketParticipant from 'components/dashboards/brackets/BracketParticipant';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { updateBracketStatus } from 'api/brackets/update-bracket';
import { first } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { dispatchControlMatchInfo } from 'dux/auth/action-thunks/event-thunk';

interface IRenderSeedPropsCustom {
  seed: any;
  breakpoint: number;
  roundIndex: number;
  seedIndex: number;
  rounds: any;
  academyId: number;
  isAcademy: boolean;
  bracketDataInfo: any;
}

const RenderSeed = ({
  breakpoint,
  seed,
  roundIndex,
  seedIndex,
  rounds,
  academyId,
  isAcademy,
  bracketDataInfo
}: IRenderSeedPropsCustom) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const { t } = useTranslation();

  const existWinner = seed.teams.some((item: any) => item.isWinner);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(isAcademy); //only display the start match modal confirmation for academies...

  const redirectToLinkMatch = async () => {
    if ((first(bracketDataInfo) as any)?.startTime == null) {
      await updateBracketStatus({
        bracketInfo: first(bracketDataInfo),
        time: 'startTime'
      });
    }

    setShow(false);

    dispatch(
      dispatchControlMatchInfo({
        controlMatchAcademyId: academyId,
        matchId: seed.id
      })
    );

    history.push(`/control-match/`);
  };
  const modalVerificationLinkMatch =
    rounds[roundIndex].isDataFake || existWinner ? (
      <div className="round-id border">{seed.id}</div>
    ) : (
      <>
        <div
          onClick={handleShow}
          className="round-id border"
          style={{ cursor: 'pointer' }}
        >
          {seed.id}
        </div>
        <Modal show={show} onHide={handleClose}>
          <div className="d-flex justify-content-end m-3">
            <FontAwesomeIcon icon="xmark" />
          </div>
          <Modal.Body>
            <h5>{t('bracketModal.question')}</h5>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              {t('bracketModal.option1')}
            </Button>
            <Button variant="primary" onClick={redirectToLinkMatch}>
              {t('bracketModal.option2')}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );

  return (
    <Seed mobileBreakpoint={breakpoint} className="seed" style={{ width: 275 }}>
      <OverlayTrigger
        trigger={['hover', 'focus']}
        key={'top'}
        placement={'top'}
        overlay={
          <Popover id={'popover-positioned-right'}>
            <Popover.Body>
              {seed.matchType} <br />
              <br />
              {`${seed.teams[0].name} (${seed.teams[0].points}) - ${seed.teams[1].name} (${seed.teams[1].points}) score`}{' '}
              <br />
            </Popover.Body>
          </Popover>
        }
      >
        <SeedItem style={{ width: 230 }} className="seed-item ">
          {modalVerificationLinkMatch}

          <SeedTeam
            className={
              rounds[roundIndex].isDataFake
                ? 'border seed-team mb-1 bg bg-light rounded'
                : 'seed-team mb-1'
            }
          >
            <BracketParticipant
              existWinner={existWinner}
              isLastRound={
                rounds[roundIndex].title.toLowerCase() === 'final' ||
                rounds[roundIndex].title.toLowerCase() === 'bronze'
              }
              name={seed.teams[0].name}
              flag={seed.teams[0].country}
              academyName={
                seed.teams[0].name.trim().toLowerCase() === 'bye'
                  ? ''
                  : seed.teams[0].academy.name
              }
              winner={seed.teams[0].isWinner}
              type={rounds[roundIndex].title.toLowerCase()}
              participants={rounds[roundIndex].participantsNumber}
              participantUUID={seed.teams[0].participantUUID}
            />
          </SeedTeam>
          <SeedTeam
            className={
              rounds[roundIndex].isDataFake
                ? 'border seed-team mb-1 bg bg-light rounded'
                : 'seed-team mb-1'
            }
          >
            <BracketParticipant
              existWinner={existWinner}
              isLastRound={
                rounds[roundIndex].title.toLowerCase() === 'final' ||
                rounds[roundIndex].title.toLowerCase() === 'bronze'
              }
              name={seed.teams[1].name}
              flag={seed.teams[1].country}
              academyName={
                seed.teams[1].name.trim().toLowerCase() === 'bye'
                  ? ''
                  : seed.teams[1].academy.name
              }
              winner={seed.teams[1].isWinner}
              type={rounds[roundIndex].title.toLowerCase()}
              participants={rounds[roundIndex].participantsNumber}
              participantUUID={seed.teams[1].participantUUID}
            />
          </SeedTeam>
        </SeedItem>
      </OverlayTrigger>
    </Seed>
  );
};

type PropType = {
  matches: IRoundProps[];
  bracketDataInfo: any;
  academyId: number;
  isAcademy: boolean;
};

const BracketDetail = ({
  matches,
  academyId,
  isAcademy,
  bracketDataInfo
}: PropType) => {
  return (
    <div
      className="bg bg-light p-4 bd-2 rounded"
      style={{ overflow: 'auto', whiteSpace: 'nowrap' }}
    >
      <Bracket
        mobileBreakpoint={0}
        rounds={matches}
        roundClassName="round"
        bracketClassName="bracket"
        renderSeedComponent={({
          seed,
          breakpoint,
          roundIndex,
          seedIndex
        }: IRenderSeedProps) => (
          <RenderSeed
            academyId={academyId}
            rounds={matches}
            bracketDataInfo={bracketDataInfo}
            seed={seed}
            breakpoint={breakpoint}
            roundIndex={roundIndex}
            isAcademy={isAcademy}
            seedIndex={seedIndex}
          />
        )}
        roundTitleComponent={(title: React.ReactNode, roundIndex: number) => {
          return (
            <div
              key={`yyuy4-${roundIndex}`}
              className="bg bg-light p-2 m-1 rounded"
            >
              <h5 className="text-center text-dark">{title}</h5>
            </div>
          );
        }}
        swipeableProps={{ enableMouseEvents: true, animateHeight: true }}
      />
    </div>
  );
};
export default BracketDetail;

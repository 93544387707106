/* eslint-disable react/prop-types */
import React from 'react';
import { Col } from 'react-bootstrap';
import ReactCountryFlag from 'react-country-flag';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import friendlyUrl from 'friendly-url';

const Participant = ({
  participantData,
  winner,
  result,
  textColor,
  cardColor,
  method,
  matchWinnerUUID,
  matchLoserUUID
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Col xs="auto d-flex align-items-center flex-1">
        <div className="align-items-start d-flex flex-column">
          {winner && (
            <strong className="winner-flag">{t('matchResults.winner')}</strong>
          )}
          <div className="d-flex">
            <div className="country-info d-flex flex-column">
              <span className="flag d-flex">
                <ReactCountryFlag countryCode={participantData.country} svg />
              </span>
            </div>
            <div className="user-info d-flex flex-column">
              <Link
                {...(matchWinnerUUID && {
                  to: `/athlete/${friendlyUrl(participantData.name)}/${
                    winner ? matchWinnerUUID : matchLoserUUID
                  }`
                })}
              >
                <strong
                  className="user-name d-flex text-uppercase"
                  style={{ color: textColor }}
                >
                  {participantData.name}
                </strong>
              </Link>
              {winner && method && (
                <span
                  className="academy-name d-block text-uppercase"
                  style={{ color: textColor }}
                >
                  {t('matchResults.winnerBy')} {method}
                </span>
              )}
            </div>
          </div>
        </div>
      </Col>
      <Col xs="auto" className="justify-content-end d-flex p-0 mx-3">
        <div
          className="score-box d-flex justify-content-center align-items-center flex-column align-items-cente mb-3"
          style={{ backgroundColor: '#3d7f2a' }}
        >
          {result.points}
        </div>
        <div
          className="score-box d-flex justify-content-center align-items-center flex-column align-items-cente mb-3"
          style={{ backgroundColor: '#e8cb10' }}
        >
          {result.advantage}
        </div>
        <div
          className="score-box d-flex justify-content-center align-items-center flex-column align-items-cente mb-3"
          style={{ backgroundColor: '#ac1439' }}
        >
          {result.penaty}
        </div>
      </Col>
    </>
  );
};

export default Participant;

/* eslint-disable react/prop-types */
import { useState, useEffect, useRef } from 'react';
import { getMatchById, putMatch, postMatchResult } from 'api/match';
import MatchAdmin from 'api/admin/match/MatchAdmin';
import ScoreCardIBJJF from 'components/pages/admin-control-match/score-card-ibjjf/ScoreCardIBJJF';
import ScoreCardDefault from 'components/pages/admin-control-match/score-card-default/ScoreCardDefault';

import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { dispatchMatchInfo } from 'dux/auth/action-thunks/event-thunk';

const mapStateToProp = state => ({
  match: {
    params: {
      matchid: state.auth.controlMatch.controlMatch_MatchId,
      academyId: state.auth.controlMatch.controlMatchAcademyId,
    }
  }
});


const ScoreCard = ({ match }) => {

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [participant1Score, setP1score] = useState({
    participant: 1,
    advantage: 0,
    penalties: 0,
    points: 0,
    winner: false,
    by: null,
    events: []
  });
  const [participant2Score, setP2score] = useState({
    participant: 2,
    advantage: 0,
    penalties: 0,
    points: 0,
    winner: false,
    by: null,
    events: []
  });

  const [time, setTime] = useState(300);
  const [data, setData] = useState(null);
  const [connectionId, setConnectionId] = useState(null);
  const [matchAdmin] = useState(new MatchAdmin(match?.params?.matchid));

  useEffect(() => {
    (async () => {
      const matchData = await loadData();
      matchAdmin.onConnection(connectionId =>
        handleConnection(matchData, connectionId)
      );
    })();
  }, []);

  const loadData = async () => {
    const response = await getMatchById(
      match?.params?.matchid,
      match?.params?.academyId
    );
    setData(response);

    setP1score(score => ({
      ...score,
      participant: response.participant1.id
    }));
    setP2score(score => ({
      ...score,
      participant: response.participant2.id
    }));
    var formatDuration = response.duration?.split(':');
    setTime(formatDuration?.length > 1 ? Number(formatDuration[0]) * 60 : Number(response.duration || 300));
    // setMatchTime(0);
    return response;
  };

  const handleConnection = async (data, connectionId) => {
    setConnectionId(connectionId);
    await putMatch(data, connectionId, '');

    dispatch(dispatchMatchInfo({
      matchAcademyId: match?.params?.academyId,
      matchId: match?.params?.matchid,
      matchConnectionId: connectionId,
    }));


    const matchWindow = window.open(`/match/`, '_match_display_window');
    if (matchWindow.location.href === "about:blank") {
      matchWindow.open("/match/");
    } else {
      matchWindow.focus();
    }
  };

  

  return (
    <>
      {data?.scoreboardType === 1 || data?.scoreboardType === null ?
        <ScoreCardDefault
          data={data}
          setP1score={setP1score}
          setP2score={setP2score}
          participant1={participant1Score}
          participant2={participant2Score}
          matchAdmin={matchAdmin}
          connectionId={connectionId}
          setTime={setTime}
          time={time} />
        :
        data?.scoreboardType === 2 ?
          <ScoreCardIBJJF
            data={data}
            setP1score={setP1score}
            setP2score={setP2score}
            participant1={participant1Score}
            participant2={participant2Score}
            matchAdmin={matchAdmin}
            connectionId={connectionId}
            setTime={setTime}
            time={time} />
          :
          ""
      }
    </>
  );
};


export default connect(mapStateToProp)(ScoreCard);

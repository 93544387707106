import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import defaultPayment from 'assets/img/icons/default-payment.png';
import paypal from 'assets/img/icons/icon-paypal-full.png';
import cardIcon from 'assets/img/icons/icon-payment-methods-grid.png';

import { useTranslation } from 'react-i18next';
import { startCase, lowerCase } from "lodash"

const PaymentMethod = ({
  academyInfo,
  entryInfo,
  processing,
  displayPayment,
  allowPaypalCardPayment,
  PaymentButton,
  paymentMethod,
  setPaymentMethod: setMethod,
}) => {
  const { t } = useTranslation();

  const { entry } = entryInfo;

  return (
    <>

      <Card.Header className="bg-light">
        <h5 className="mb-0">
          {t('events.eventDetail.entryDetail.paymentMethodTitle')}
        </h5>
      </Card.Header>
      <Card.Body>

        {/* Only Display the Paypal & Card Radio Options when the entry isn't free...  */}
        {Number(entry.price) ? (
          <>

            {academyInfo && academyInfo.defaultPaymentInfo && (
              <>
                <Form.Check
                  type="radio"
                  name="payment-method"
                  id="default-payment"
                  onChange={e => setMethod(e.target.id)}
                  checked={paymentMethod === 'default-payment'}
                  className="d-flex align-items-center"
                >
                  <Form.Check.Input
                    type="radio"
                    onChange={e => setMethod(e.target.id)}
                    checked={paymentMethod === 'default-payment'}
                    name="payment-method"
                  />

                  <Form.Check.Label className="mb-0 ms-2">
                    <img src={defaultPayment} height={20} alt="card payment options" />
                    <Form.Check.Label className="ms-2 mt-3 fs-0">
                      {startCase(lowerCase(academyInfo.defaultPaymentInfo))}
                    </Form.Check.Label>
                  </Form.Check.Label>

                </Form.Check>

                <div className="border-dashed-bottom mt-3 mb-3"></div>
              </>
            )}

            <Form.Check
              type="radio"
              name="payment-method"
              id="paypal"
              onChange={e => setMethod(e.target.id)}
              checked={paymentMethod === 'paypal'}
              className="d-flex align-items-center"
            >
              <Form.Check.Input
                type="radio"
                onChange={e => setMethod(e.target.id)}
                checked={paymentMethod === 'paypal'}
                name="payment-method"
              />
              <Form.Check.Label className="mb-0 ms-2">
                <img src={paypal} height={20} alt="card payment options" />
              </Form.Check.Label>
            </Form.Check>

            <div className="border-dashed-bottom mt-3 mb-3"></div>

            {allowPaypalCardPayment && (
              <>
                <Form.Check
                  type="radio"
                  id="card"
                  name="payment-method"
                  onChange={e => setMethod(e.target.id)}
                  checked={paymentMethod === 'card'}
                  className="d-flex align-items-center"
                >
                  <Form.Check.Input
                    type="radio"
                    onChange={e => setMethod(e.target.id)}
                    checked={paymentMethod === 'card'}
                    name="payment-method"
                  />
                  <Form.Check.Label className="mb-0 ms-2">
                    <img src={cardIcon} height={20} alt="card payment options" />
                    <Form.Check.Label className="ms-2 mt-3 fs-0">
                      {t("events.eventDetail.entryDetail.billing.creditCard")}
                    </Form.Check.Label>
                  </Form.Check.Label>
                </Form.Check>

                <div className="border-dashed-bottom mt-3 mb-3"></div>
              </>

            )}

          </>
        ) : null}

        <Row>
          {displayPayment ?
            (
              <div className='mt-4 mb-3'>
                <PaymentButton
                  paymentFundingSource={paymentMethod}
                />
              </div>
            )
            : (
              <Col
                md={12}
                className="ps-xxl-5 text-center text-md-start text-xl-center text-xxl-start d-flex justify-content-center mt-2 mb-3"
              >
                <div className="border-dashed-bottom d-block d-md-none d-xl-block d-xxl-none my-4"></div>

                <Button
                  variant="success"
                  type="submit"
                  disabled={processing}
                  className="mt-3 px-5 py-3 mx-auto"
                >
                  {processing
                    ? t(
                      'events.eventDetail.entryDetail.paymentButtonProcessingLabel'
                    )
                    : Number(entry.price)
                      ? t('events.eventDetail.entryDetail.paymentButtonLabel')
                      : t('events.eventDetail.entryDetail.freePaymentButtonLabel')}
                </Button>
              </Col>
            )}

        </Row>
      </Card.Body>

    </>
  );
};

export default PaymentMethod;
